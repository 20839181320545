import print from "print-js";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { PiCopy, PiDownloadSimple, PiEye, PiGear, PiPrinter } from "react-icons/pi";
import OutsideClickHandler from "react-outside-click-handler";
import { Link } from "react-router-dom";

import useBom from "../../../../hooks/useBom";
import useCloneBom from "../../../../hooks/useCloneBom";
import useQualificationPdf from "../../../../hooks/useQualificationPdf";
import bomDetail from "../../../../propTypes/bomDetail";
import LoadingSpinner from "../../../LoadingSpinner/LoadingSpinner";
import PDFViewerModal from "../../../PDFViewerModal/PDFViewerModal";

function DuplicateButton({ bom }) {
  const { handleClone, isCloning } = useCloneBom();

  return (
    <button
      onClick={() => handleClone(bom)}
      type="button"
      className="btn bom-card__settings-btn-dropdown-item d-flex align-items-center gap-1 justify-content-start w-100"
      disabled={isCloning}
    >
      <PiCopy />
      Duplicate
    </button>
  );
}

DuplicateButton.propTypes = {
  bom: bomDetail.isRequired,
};

function DownloadReportButton({ bom }) {
  const [showPdfReader, setShowPdfReader] = useState(false);
  const { pdf } = useQualificationPdf(bom.BOMID);

  const handleClick = useCallback(() => {
    setShowPdfReader(true);
  }, []);

  return (
    <>
      <button onClick={handleClick} type="button" className="btn bom-card__settings-btn-dropdown-item w-100 d-flex align-items-center gap-1 justify-content-start">
        <PiDownloadSimple />
        View Report
      </button>
      <PDFViewerModal show={showPdfReader} title={`BOM ID | ${bom.BOMID} Qualification Report`} downloadUrl={pdf?.url} onHide={() => setShowPdfReader(false)} />
    </>
  );
}

DownloadReportButton.propTypes = {
  bom: bomDetail.isRequired,
};

function PrintReportButton({ bom }) {
  const { pdf } = useQualificationPdf(bom.BOMID);

  const handleClick = useCallback(() => {
    if (pdf && pdf.url) {
      fetch(pdf.url)
        .then((response) => {
          if (response.ok) {
            return response.blob();
          }
          return false;
        })
        .then((responseData) => {
          if (responseData) {
            print(URL.createObjectURL(responseData));
          }
        });
    }
  }, [pdf]);

  return (
    <button onClick={handleClick} type="button" className="btn bom-card__settings-btn-dropdown-item w-100 d-flex align-items-center gap-1 justify-content-start">
      <PiPrinter />
      Print Report
    </button>
  );
}

PrintReportButton.propTypes = {
  bom: bomDetail.isRequired,
};

function ViewBOMButton({ bom }) {
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState(null);
  const [bomId, setBomId] = useState(null);

  useEffect(() => {
    if (bom) {
      const [part1, part2] = bom.BOMID.split("#");
      setUsername(part1);
      setBomId(part2);
      setLoading(false);
    } else {
      setUsername(null);
      setBomId(null);
    }

    return () => {
      setLoading(true);
      setUsername(null);
      setBomId(null);
    };
  }, [bom]);

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <Link className="btn bom-card__settings-btn-dropdown-item w-100 d-flex align-items-center gap-1 justify-content-start" to={`/dashboard/bom/${username}/${bomId}/`}>
      <PiEye />
      View BOM
    </Link>
  );
}

ViewBOMButton.propTypes = {
  bom: bomDetail.isRequired,
};

function SettingsButton({ bomId }) {
  const { bom, loading } = useBom(bomId);
  const [showDropdown, setShowDropdown] = useState(false);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="position-relative">
      <OutsideClickHandler onOutsideClick={() => setShowDropdown(false)}>
        <>
          <button onClick={() => setShowDropdown(!showDropdown)} data-current-bom-id={bom.BOMID} type="button" className="btn bom-card__settings-btn">
            <PiGear />
          </button>
          <div
            style={{ backgroundColor: "white" }}
            className={`bom-card__settings-btn-dropdown ${
              showDropdown ? "bom-card__settings-btn-dropdown--active" : ""
            } d-flex flex-column position-absolute rounded p-2`}
          >
            <ViewBOMButton bom={bom} />
            <DuplicateButton bom={bom} />
            {bom && bom.BOM_State === "CLOSED" && (
              <>
                <DownloadReportButton bom={bom} />
                <PrintReportButton bom={bom} />
              </>
            )}
          </div>
        </>
      </OutsideClickHandler>
    </div>
  );
}

SettingsButton.propTypes = {
  bomId: PropTypes.string.isRequired,
};

export default SettingsButton;
