import Decimal from "decimal.js";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { componentsSelector } from "../../../features/bomWizard/bomWizardSlice";

export default function useExtCosts() {
  const components = useSelector(componentsSelector);
  const [extCosts, setExtCosts] = useState([]);
  const calculateExtCost = useCallback(
    (qty, cost, index) => {
      let parsedQty;
      let parsedCost;
      if (qty) {
        parsedQty = parseFloat(qty);
        if (parsedQty) {
          if (Number.isNaN(parsedQty)) {
            return;
          }
        }
      }

      if (cost) {
        parsedCost = new Decimal(cost);
        if (parsedCost) {
          if (Number.isNaN(parsedCost)) {
            return;
          }
        }
      } else {
        parsedCost = new Decimal(0);
      }

      const newExtCost = parsedCost.mul(parsedQty);
      if (!Number.isNaN(newExtCost)) {
        setExtCosts((currentCosts) => {
          let newCosts;
          if (currentCosts && Array.isArray(currentCosts)) {
            newCosts = [...currentCosts];
          }
          newCosts[index] = newExtCost.toNumber();
          return newCosts;
        });
      }
    },
    [setExtCosts],
  );

  useEffect(() => {
    if (components) {
      components.forEach((lineItem, idx) => {
        if (lineItem.Qty && lineItem.Cost) {
          calculateExtCost(parseFloat(lineItem.Qty), parseFloat(lineItem.Cost), idx);
        }
      });
    }
  }, [components, calculateExtCost]);

  return useMemo(() => ({ extCosts, setExtCosts, calculateExtCost }), [extCosts, setExtCosts, calculateExtCost]);
}
