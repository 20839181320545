import * as Sentry from "@sentry/react";
import bootstrapIcons from "bootstrap-icons/bootstrap-icons.svg";
import { Field, FieldArray, Form, Formik } from "formik";
import { nanoid } from "nanoid";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { array, boolean, number, object, string } from "yup";

import BOMDetails from "../../../components/BOMDetails/BOMDetails";
import FormError from "../../../components/FormError";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import countryCodes from "../../../data/country-codes.json";
import { useCreateComponentsMutation } from "../../../features/api/boms";
import { bomIdSelector, componentsSelector, defaultCurrencySelector, setComponentQuestions, setComponents } from "../../../features/bomWizard/bomWizardSlice";
import buildHtsValue from "../../../utils/buildHtsValue";
import unpackHts from "../../../utils/unpackHts";
import ProgressSteps from "../components/ProgressSteps";
import "./EnterComponents.scss";
import useExtCosts from "./useExtCosts";

const lineItemsSchema = object().shape({
  lineItems: array().of(
    object().shape({
      Part: string(),
      Hts: string()
        .min(12, "Hts must contain at least 10 digits.")
        .max(12, "Hts must contain no more than 10 digits.")
        .test("USHTSNumbersOnly", "USHTS must contain numbers only.", (value) => !/[a-zA-Z]/.test(value))
        .required("HTS is required."),
      Desc: string().required("Description is required."),
      Qty: number().positive("Quantity must be greater than zero.").required("Quantity is required."),
      Unit: string(),
      Cost: number().positive("Cost must be greater than zero.").required("Cost is required."),
      Origin: string(),
      USMCA: boolean(),
    }),
  ),
});

const initialLineItem = {
  Part: "",
  Hts: "0000.00.0000",
  Desc: "",
  Qty: 1,
  Unit: "pieces",
  Cost: 0,
  Origin: "US",
  USMCA: false,
};

export default function EnterComponents() {
  const navigate = useNavigate();
  const { extCosts, setExtCosts, calculateExtCost } = useExtCosts();
  const [createComponents, { isError, error }] = useCreateComponentsMutation();
  const currency = useSelector(defaultCurrencySelector);
  const bomId = useSelector(bomIdSelector);
  const components = useSelector(componentsSelector);
  const dispatch = useDispatch();
  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      setSubmitting(true);
      const lineItems = values.lineItems.map((lineItem, idx) => {
        const lineItemCopy = { ...lineItem };
        lineItemCopy.Hts = `${lineItemCopy.Hts.slice(0, 4)}${lineItemCopy.Hts.slice(5, 7)}${lineItemCopy.Hts.slice(8)}`;
        if (lineItemCopy.Hts[0] === "0") {
          lineItemCopy.Hts = lineItemCopy.Hts.slice(1);
        }
        lineItemCopy.LineItem = idx + 1;
        if (/US|MX|CA/.test(lineItemCopy.Origin)) {
          lineItemCopy.USMCA = lineItemCopy.USMCA ? "Y" : "N";
        } else {
          lineItemCopy.USMCA = "N";
        }
        return lineItemCopy;
      });
      const requestBody = {
        BOMID: bomId,
        Components: lineItems,
      };
      try {
        const response = await createComponents(requestBody);
        const lineItemsWithCompId = [];
        response.data.Components.forEach((component) => {
          const lineItem = lineItems[parseInt(component.LineItem, 10) - 1];
          const lineItemCopy = { ...lineItem };
          lineItemCopy.CompID = component.CompID;
          lineItemsWithCompId.push(lineItemCopy);
        });
        dispatch(setComponents(lineItemsWithCompId));
        dispatch(setComponentQuestions(response.data));
        navigate("/dashboard/component-questions/");
      } catch (err) {
        if (err.response?.status !== 401) {
          Sentry.captureException(err);
        }
      }
      setSubmitting(false);
    },
    [dispatch, bomId, navigate, createComponents],
  );

  return (
    <>
      <div className="d-flex justify-content-center mb-5">
        <ProgressSteps activeIdx={2} />
      </div>
      <h1 className="text-center mb-5">Enter Components</h1>
      <div className="row mb-5">
        <div className="col-12">
          <BOMDetails />

          <div>
            <h2 className="text-center mt-5 mb-4">Components</h2>
            {isError && <p className="alert alert-danger">{JSON.stringify(error.data || "Unknown error")}</p>}
            <Formik
              validationSchema={lineItemsSchema}
              validateOnBlur
              validateOnChange={false}
              initialValues={{
                lineItems: components
                  ? components.map((lineItem) => {
                      const lineItemCopy = { ...lineItem };
                      lineItemCopy.Hts = unpackHts(lineItemCopy.Hts);
                      lineItemCopy.USMCA = lineItemCopy.USMCA === "Y";
                      return lineItemCopy;
                    })
                  : [initialLineItem],
              }}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, values, errors, touched, handleChange, handleBlur }) => (
                <Form>
                  {isSubmitting ? (
                    <div className="d-flex justify-content-center">
                      <LoadingSpinner />
                    </div>
                  ) : (
                    <FieldArray name="lineItems">
                      {(arrayHelpers) => (
                        <div>
                          <table className="table components-table mb-4">
                            <thead>
                              <tr>
                                <th scope="col">LI</th>
                                <th scope="col">PART NUMBER</th>
                                <th scope="col">HTS</th>
                                <th scope="col">DESC</th>
                                <th scope="col">QTY</th>
                                <th scope="col">UNITS</th>
                                <th scope="col">COST/UNIT ({currency})</th>
                                <th scope="col">EXT COST ({currency})</th>
                                <th scope="col">Country of Origin (COO)</th>
                                <th scope="col">Component USMCA on file</th>
                                <th scope="col">Remove</th>
                              </tr>
                            </thead>
                            <tbody>
                              {values.lineItems && values.lineItems.length > 0
                                ? values.lineItems.map((lineItem, idx) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <tr key={idx}>
                                      <td>{idx + 1}</td>
                                      <td>
                                        <Field
                                          type="text"
                                          className={`form-control ${
                                            errors.lineItems !== undefined &&
                                            Array.isArray(errors.lineItems) &&
                                            errors.lineItems[idx] !== undefined &&
                                            touched.lineItems !== undefined &&
                                            Array.isArray(touched.lineItems) &&
                                            touched.lineItems[idx] !== undefined &&
                                            errors.lineItems[idx].Part
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          name={`lineItems.${idx}.Part`}
                                          placeholder="ABCD001"
                                        />
                                        <FormError
                                          error={
                                            errors.lineItems !== undefined &&
                                            Array.isArray(errors.lineItems) &&
                                            errors.lineItems[idx] !== undefined &&
                                            touched.lineItems !== undefined &&
                                            Array.isArray(touched.lineItems) &&
                                            touched.lineItems[idx] !== undefined
                                              ? errors.lineItems[idx].Part
                                              : null
                                          }
                                        />
                                      </td>
                                      <td>
                                        <Field
                                          name={`lineItems.${idx}.Hts`}
                                          type="text"
                                          placeholder="0000.00.0000"
                                          className={`form-control ${
                                            errors.lineItems !== undefined &&
                                            Array.isArray(errors.lineItems) &&
                                            errors.lineItems[idx] !== undefined &&
                                            touched.lineItems !== undefined &&
                                            Array.isArray(touched.lineItems) &&
                                            touched.lineItems[idx] !== undefined &&
                                            errors.lineItems[idx].Hts
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          value={buildHtsValue(values.lineItems[idx].Hts)}
                                        />
                                        <FormError
                                          error={
                                            errors.lineItems !== undefined &&
                                            Array.isArray(errors.lineItems) &&
                                            errors.lineItems[idx] !== undefined &&
                                            touched.lineItems !== undefined &&
                                            Array.isArray(touched.lineItems) &&
                                            touched.lineItems[idx] !== undefined
                                              ? errors.lineItems[idx].Hts
                                              : null
                                          }
                                        />
                                      </td>
                                      <td>
                                        <Field
                                          name={`lineItems.${idx}.Desc`}
                                          type="text"
                                          placeholder="Yarn"
                                          className={`form-control ${
                                            errors.lineItems !== undefined &&
                                            Array.isArray(errors.lineItems) &&
                                            errors.lineItems[idx] !== undefined &&
                                            touched.lineItems !== undefined &&
                                            Array.isArray(touched.lineItems) &&
                                            touched.lineItems[idx] !== undefined &&
                                            errors.lineItems[idx].Desc
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                        />
                                        <FormError
                                          error={
                                            errors.lineItems !== undefined &&
                                            Array.isArray(errors.lineItems) &&
                                            errors.lineItems[idx] !== undefined &&
                                            touched.lineItems !== undefined &&
                                            Array.isArray(touched.lineItems) &&
                                            touched.lineItems[idx] !== undefined
                                              ? errors.lineItems[idx].Desc
                                              : null
                                          }
                                        />
                                      </td>

                                      <td>
                                        <input
                                          onChange={handleChange}
                                          onBlur={(e) => {
                                            calculateExtCost(e.target.value, values.lineItems[idx].Cost, idx);
                                            handleBlur(e);
                                          }}
                                          className={`form-control ${
                                            errors.lineItems !== undefined &&
                                            Array.isArray(errors.lineItems) &&
                                            errors.lineItems[idx] !== undefined &&
                                            touched.lineItems !== undefined &&
                                            Array.isArray(touched.lineItems) &&
                                            touched.lineItems[idx] !== undefined &&
                                            errors.Qty
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          type="number"
                                          name={`lineItems.${idx}.Qty`}
                                          value={values.lineItems[idx].Qty}
                                        />
                                        <FormError
                                          error={
                                            errors.lineItems !== undefined &&
                                            Array.isArray(errors.lineItems) &&
                                            errors.lineItems[idx] !== undefined &&
                                            touched.lineItems !== undefined &&
                                            Array.isArray(touched.lineItems) &&
                                            touched.lineItems[idx] !== undefined
                                              ? errors.lineItems[idx].Qty
                                              : null
                                          }
                                        />
                                      </td>

                                      <td>
                                        <Field
                                          type="text"
                                          name={`lineItems.${idx}.Unit`}
                                          className={`form-control ${
                                            errors.lineItems !== undefined &&
                                            Array.isArray(errors.lineItems) &&
                                            errors.lineItems[idx] !== undefined &&
                                            touched.lineItems !== undefined &&
                                            Array.isArray(touched.lineItems) &&
                                            touched.lineItems[idx] !== undefined &&
                                            errors.lineItems.Unit
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                        />
                                        <FormError
                                          error={
                                            errors.lineItems !== undefined &&
                                            Array.isArray(errors.lineItems) &&
                                            errors.lineItems[idx] !== undefined &&
                                            touched.lineItems !== undefined &&
                                            Array.isArray(touched.lineItems) &&
                                            touched.lineItems[idx] !== undefined
                                              ? errors.lineItems[idx].Unit
                                              : null
                                          }
                                        />
                                      </td>

                                      <td>
                                        <input
                                          onChange={handleChange}
                                          onBlur={(e) => {
                                            calculateExtCost(values.lineItems[idx].Qty, e.target.value, idx);
                                            handleBlur(e);
                                          }}
                                          className={`form-control ${
                                            errors.lineItems !== undefined &&
                                            Array.isArray(errors.lineItems) &&
                                            errors.lineItems[idx] !== undefined &&
                                            touched.lineItems !== undefined &&
                                            Array.isArray(touched.lineItems) &&
                                            touched.lineItems[idx] !== undefined &&
                                            errors.lineItems[idx].Cost
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          type="number"
                                          name={`lineItems.${idx}.Cost`}
                                          value={values.lineItems[idx].Cost}
                                        />
                                        <FormError
                                          error={
                                            errors.lineItems !== undefined &&
                                            Array.isArray(errors.lineItems) &&
                                            errors.lineItems[idx] !== undefined &&
                                            touched.lineItems !== undefined &&
                                            Array.isArray(touched.lineItems) &&
                                            touched.lineItems[idx] !== undefined
                                              ? errors.lineItems[idx].Cost
                                              : null
                                          }
                                        />
                                      </td>

                                      <td>{extCosts[idx]}</td>
                                      <td>
                                        <Field
                                          style={{ width: "5rem" }}
                                          className={`form-select ${
                                            errors.lineItems !== undefined &&
                                            Array.isArray(errors.lineItems) &&
                                            errors.lineItems[idx] !== undefined &&
                                            touched.lineItems !== undefined &&
                                            Array.isArray(touched.lineItems) &&
                                            touched.lineItems[idx] !== undefined &&
                                            errors.Origin
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          as="select"
                                          name={`lineItems.${idx}.Origin`}
                                        >
                                          {countryCodes.map((countryCode) => (
                                            <option key={nanoid()} value={countryCode}>
                                              {countryCode}
                                            </option>
                                          ))}
                                        </Field>
                                        <FormError
                                          error={
                                            errors.lineItems !== undefined &&
                                            Array.isArray(errors.lineItems) &&
                                            errors.lineItems[idx] !== undefined &&
                                            touched.lineItems !== undefined &&
                                            Array.isArray(touched.lineItems) &&
                                            touched.lineItems[idx] !== undefined
                                              ? errors.lineItems[idx].Origin
                                              : null
                                          }
                                        />
                                      </td>

                                      <td>
                                        <Field
                                          type="checkbox"
                                          name={`lineItems.${idx}.USMCA`}
                                          className={`form-check-input ${
                                            errors.lineItems !== undefined &&
                                            Array.isArray(errors.lineItems) &&
                                            errors.lineItems[idx] !== undefined &&
                                            touched.lineItems !== undefined &&
                                            Array.isArray(touched.lineItems) &&
                                            touched.lineItems[idx] !== undefined &&
                                            errors.lineItems[idx].USMCA
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          value={values.lineItems[idx].USMCA}
                                          checked={values.lineItems[idx].USMCA}
                                          disabled={!/US|MX|CA/.test(values.lineItems[idx]?.Origin)}
                                        />
                                        <FormError
                                          error={
                                            errors.lineItems !== undefined &&
                                            Array.isArray(errors.lineItems) &&
                                            errors.lineItems[idx] !== undefined &&
                                            touched.lineItems !== undefined &&
                                            Array.isArray(touched.lineItems) &&
                                            touched.lineItems[idx] !== undefined
                                              ? errors.lineItems[idx].USMCA
                                              : null
                                          }
                                        />
                                      </td>

                                      <td>
                                        <button
                                          onClick={() => {
                                            if (extCosts[idx]) {
                                              const extCostsCopy = [...extCosts];
                                              extCostsCopy.splice(idx, 1);
                                              setExtCosts(extCostsCopy);
                                            }
                                            arrayHelpers.remove(idx);
                                          }}
                                          className="m-0 p-0 bg-transparent btn ms-2 text-danger"
                                          type="button"
                                        >
                                          <svg className="bi" width="32" height="32" fill="currentColor">
                                            <use xlinkHref={`${bootstrapIcons}#x-circle`} />
                                          </svg>
                                        </button>
                                      </td>
                                    </tr>
                                  ))
                                : ""}
                            </tbody>
                          </table>
                          <button
                            type="button"
                            onClick={() => {
                              arrayHelpers.push(initialLineItem);
                              setExtCosts([...extCosts, 0]);
                            }}
                            className="btn btn-primary w-100"
                          >
                            Add item
                          </button>
                        </div>
                      )}
                    </FieldArray>
                  )}
                  <button type="submit" className="btn btn-success w-100 mt-4" disabled={isSubmitting}>
                    Submit
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}
