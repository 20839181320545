import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { setCurrentBOMImportIndex, setSuccessfulImports, setUnParsedBOMs } from "../../features/fileImport/fileImportSlice";
import useBom from "../../hooks/useBom";

export default function useBomDetail() {
  const [bomId, setBomId] = useState(null);
  const { username, bomId: bomTimestamp } = useParams();
  const { bom, loading } = useBom(bomId);
  const dispatch = useDispatch();

  useEffect(() => {
    if (dispatch) {
      dispatch(setUnParsedBOMs([]));
      dispatch(setCurrentBOMImportIndex(0));
      dispatch(setSuccessfulImports([]));
    }
  }, [dispatch]);

  useEffect(() => {
    if (username && bomTimestamp) {
      setBomId(`${username}#${bomTimestamp}`);
    } else {
      setBomId(null);
    }

    return () => {
      setBomId(null);
    };
  }, [username, bomTimestamp]);

  return useMemo(() => ({ bom, loading }), [bom, loading]);
}
