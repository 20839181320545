import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useAnswerFinishedGoodQuestionsMutation } from "../../../../features/api/boms";
import { clearState, newBomIdSelector, setShowFgQuestionsModal } from "../../../../features/fileImport/fileImportSlice";
import useBom from "../../../../hooks/useBom";

export default function useFinishedGoodQuestionsModal() {
  const answerFinishedGoodQuestions = useAnswerFinishedGoodQuestionsMutation()[0];
  const newBomId = useSelector(newBomIdSelector);
  const { bom } = useBom(newBomId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    dispatch(setShowFgQuestionsModal(false));
    dispatch(clearState());
    const [part1, part2] = bom.BOMID.split("#");
    navigate(`/dashboard/bom/${part1}/${part2}/`);
  }, [bom, dispatch, navigate]);

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      setSubmitting(true);
      const questionsWithAnswers = [];
      for (let i = 0; i < bom.FG_Qs.length; i += 1) {
        const answer = values[`question${i}`];
        const question = bom.FG_Qs[i];
        questionsWithAnswers.push({ ...question, Answer: answer });
      }
      answerFinishedGoodQuestions({ BOMID: bom.BOMID, As: questionsWithAnswers }).finally(() => {
        setSubmitting(false);
      });
    },
    [answerFinishedGoodQuestions, bom],
  );

  useEffect(() => {
    if (bom && bom.BOM_State === "FG_QUALIFYING") {
      dispatch(setShowFgQuestionsModal(true));
    } else {
      dispatch(setShowFgQuestionsModal(false));
    }
  }, [bom, dispatch]);

  return useMemo(() => ({ handleSubmit, handleClose }), [handleSubmit, handleClose]);
}
