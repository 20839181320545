import dayjs from "dayjs";

import { getTimestamp } from "../../utils/bom";
import api from "./apiSlice";

const bomsApi = api.injectEndpoints({
  endpoints(build) {
    return {
      listBoms: build.query({
        query() {
          return "/boms";
        },
        transformResponse(baseQueryReturnValue) {
          if (baseQueryReturnValue.BOMs && Array.isArray(baseQueryReturnValue.BOMs) && baseQueryReturnValue.BOMs.length > 0) {
            const boms = [...baseQueryReturnValue.BOMs];
            boms.sort((bomA, bomB) => {
              const timeStampA = dayjs.unix(getTimestamp(bomA));
              const timestampB = dayjs.unix(getTimestamp(bomB));
              if (timeStampA.isBefore(timestampB)) {
                return 1;
              }
              if (timeStampA.isAfter(timestampB)) {
                return -1;
              }
              return 0;
            });
            const open = boms.filter((b) => b.BOM_State !== "CLOSED");
            const completed = boms.filter((b) => b.BOM_State === "CLOSED");
            return { open, completed };
          }
          return { open: [], completed: [] };
        },
        providesTags: [{ type: "BOM", id: "LIST" }],
      }),
      getBom: build.query({
        query({ username, bomId }) {
          return `/bom?part1=${username}&part2=${bomId}`;
        },
        providesTags(result, error, { username, bomId }) {
          if (result) {
            return [{ type: "BOM", id: `${username}#${bomId}` }];
          }
          return [];
        },
      }),
      createBOM: build.mutation({
        query: ({ Part, Desc, Hts, Value, Origin, Currency, NetCost, TotalCost }) => ({
          url: "/bom",
          method: "POST",
          body: { Part, Desc, Hts, Value, Origin, Currency, NetCost, TotalCost },
        }),
        invalidatesTags: [{ type: "BOM", id: "LIST" }],
      }),
      createComponents: build.mutation({
        query({ BOMID, Components }) {
          return {
            url: "/comps",
            method: "POST",
            body: { BOMID, Components },
          };
        },
        invalidatesTags(result, error, { BOMID }) {
          return [
            { type: "BOM", id: BOMID },
            { type: "BOM", id: "LIST" },
          ];
        },
      }),
      answerFinishedGoodQuestions: build.mutation({
        query: ({ BOMID, As }) => ({
          url: "/fg",
          method: "PUT",
          body: { BOMID, As },
        }),
        invalidatesTags(result, error, { BOMID }) {
          return [
            { type: "BOM", id: BOMID },
            { type: "BOM", id: "LIST" },
          ];
        },
      }),
      answerComponentQuestions: build.mutation({
        query({ BOMID, As }) {
          return {
            url: "/comps",
            method: "PUT",
            body: { BOMID, As },
          };
        },
        invalidatesTags(result, error, { BOMID }) {
          return [
            { type: "BOM", id: BOMID },
            { type: "BOM", id: "LIST" },
          ];
        },
      }),
      closeBom: build.mutation({
        query({ BOMID }) {
          return {
            url: "/bom",
            method: "PUT",
            body: { BOMID },
          };
        },
        invalidatesTags(result, error, { BOMID }) {
          return [
            { type: "BOM", id: BOMID },
            { type: "BOM", id: "LIST" },
          ];
        },
      }),
    };
  },
});

export const {
  useListBomsQuery,
  useGetBomQuery,
  useCreateBOMMutation,
  useCreateComponentsMutation,
  useAnswerFinishedGoodQuestionsMutation,
  useAnswerComponentQuestionsMutation,
  useCloseBomMutation,
} = bomsApi;
