import PropTypes from "prop-types";
import React from "react";

import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import useBom from "../../../../hooks/useBom";
import { htsDisplay } from "../../../../utils/bom";

function QualifiedBOMCard({ bomId, selected, onClick }) {
  const { bom, loading } = useBom(bomId);

  let body;

  if (loading) {
    body = (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <LoadingSpinner />
      </div>
    );
  } else {
    body = (
      <div className="d-flex flex-column justify-content-between h-100">
        <div>
          <h4>{bom?.FG.Part}</h4>
          <p>{bom?.FG.Desc}</p>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <p className="m-0">Producer | {bom?.FG.Producer}</p>
          <p className="m-0">BOM ID | {bom?.BOMID}</p>
          <p className="m-0">HTS | {htsDisplay(bom?.FG.Hts)}</p>
        </div>
      </div>
    );
  }

  return (
    <button onClick={onClick} type="button" className="btn w-100 text-start p-0">
      <div className={`border ${selected ? "border-her-highness border-2" : "border-light-3"} bg-light-2 rounded usmca-certificate__qualified-bom-card w-100 p-3`}>
        {body}
      </div>
    </button>
  );
}

QualifiedBOMCard.defaultProps = {
  selected: false,
};

QualifiedBOMCard.propTypes = {
  bomId: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default QualifiedBOMCard;
