import { Field, useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";

import FormError from "../FormError";

function FormikFieldGroup({ label, name, type, placeholder, required, extraClasses, disabled, helpText }) {
  const { errors } = useFormikContext();
  return (
    <>
      {label && (
        <label htmlFor={`input${name}`} className="form-label">
          {label}
        </label>
      )}
      <Field
        disabled={disabled}
        type={type}
        name={name}
        id={`input${name}`}
        placeholder={placeholder}
        required={required}
        className={`form-control ${errors && errors[name] ? "is-invalid" : ""} ${extraClasses}`}
      />
      {helpText && <div className="form-text">{helpText}</div>}
      {errors && errors[name] && <FormError error={errors[name]} />}
    </>
  );
}

FormikFieldGroup.defaultProps = {
  type: "text",
  placeholder: "",
  required: false,
  label: null,
  extraClasses: "",
  disabled: false,
};

FormikFieldGroup.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  extraClasses: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FormikFieldGroup;
