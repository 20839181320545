import { useEffect, useMemo, useState } from "react";

import { useGetBomQuery } from "../../../features/api/boms";

export default function useBomDetail(bomId) {
  const [readyToFetch, setReadyToFetch] = useState(false);
  const [username, setUsername] = useState(null);
  const [timeStamp, setTimeStamp] = useState(null);
  const { data: bomDetail } = useGetBomQuery({ username, bomId: timeStamp }, { skip: !readyToFetch });

  useEffect(() => {
    if (bomId) {
      const parts = bomId.split("#");
      setUsername(parts[0]);
      setTimeStamp(parts[1]);
    }

    return () => {
      setUsername(null);
      setTimeStamp(null);
    };
  }, [bomId]);

  useEffect(() => {
    if (username && timeStamp) {
      setReadyToFetch(true);
    }
    return () => {
      setReadyToFetch(false);
    };
  }, [username, timeStamp]);

  return useMemo(() => ({ bomDetail }), [bomDetail]);
}
