import { nanoid } from "nanoid";
import { PiCards, PiList } from "react-icons/pi";

import ComponentCard from "../../components/ComponentCard/ComponentCard";
import ComponentsTable from "../../components/ComponentsTable/ComponentsTable";
import FinishedGoodDetailsCard from "../../components/FinishedGoodDetailsCard/FinishedGoodDetailsCard";
import FinishedGoodDetailsTable from "../../components/FinishedGoodDetailsTable/FinishedGoodDetailsTable";
import FinishedGoodQuestions from "../../components/FinishedGoodQuestions/FinishedGoodQuestions";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import QuestionsListGroup from "../../components/QuestionsListGroup/QuestionsListGroup";
import useToggleView from "../../hooks/useToggleView";
import { useBomContext } from "./BOMContext";

function Components({ bom }) {
  const { currentView } = useToggleView();
  if (!bom) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (currentView === "card") {
    return (
      <div>
        {bom.Components.map((component) => (
          <div key={nanoid()} className="mb-3">
            <ComponentCard component={component} />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="mb-5">
      <ComponentsTable bomId={bom.BOMID} />
    </div>
  );
}

export default function BOMContents() {
  const { currentView, handleToggleView } = useToggleView();
  const { bom } = useBomContext();
  if (!bom) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div>
      <div className="mb-4">
        <button
          onClick={() => handleToggleView()}
          type="button"
          className="btn btn-outline-primary fw-bold w-100 text-uppercase d-flex align-items-center gap-2 justify-content-center"
        >
          {currentView === "card" ? (
            <>
              View as List <PiList />
            </>
          ) : (
            <>
              View as card <PiCards />
            </>
          )}
        </button>
      </div>
      <div className="mb-5 d-flex flex-column gap-4">
        <div>{currentView === "card" ? <FinishedGoodDetailsCard bomId={bom.BOMID} /> : <FinishedGoodDetailsTable bomId={bom.BOMID} />}</div>
        {bom.FG_Qs.length > 0 && <FinishedGoodQuestions bom={bom} />}
      </div>
      {bom.Components.length > 0 && (
        <div className="d-flex flex-column mb-5">
          <div className="mb-3">
            <Components bom={bom} />
          </div>
          {bom.Comp_Qs.length > 0 && (
            <div>
              <h3 className="mb-3">Questions</h3>
              <QuestionsListGroup questions={bom.Comp_Qs} forComponent />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
