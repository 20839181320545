import { nanoid } from "nanoid";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const itemPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  active: PropTypes.bool,
  percentComplete: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object,
});

function ProgressBar({ percentComplete }) {
  return (
    <div style={{ height: ".25rem", minWidth: "4.75rem" }} className="progress flex-grow-1">
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <div
        style={{ width: `${percentComplete}%` }}
        className="progress-bar bg-dark"
        role="progressbar"
        aria-valuenow={percentComplete}
        aria-valuemin={percentComplete}
        aria-valuemax="100"
      />
    </div>
  );
}

ProgressBar.defaultProps = {
  percentComplete: 0,
};

ProgressBar.propTypes = {
  percentComplete: PropTypes.number,
};

function ProgressItemName({ url, locationState, name, isActive }) {
  if (url) {
    return (
      <Link style={{ fontSize: ".75rem", fontWeight: "500" }} className={`flex-shrink-1 ${isActive ? "text-dark" : "text-muted"}`} to={url} state={locationState}>
        {name}
      </Link>
    );
  }
  return (
    <span style={{ fontSize: ".75rem", fontWeight: "500" }} className={`flex-shrink-1 ${isActive ? "text-dark" : "text-muted"}`}>
      {name}
    </span>
  );
}

ProgressItemName.defaultProps = {
  url: null,
  locationState: {},
  isActive: false,
};

ProgressItemName.propTypes = {
  url: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  locationState: PropTypes.object,
  name: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
};

function ProgressItem({ item, withBar }) {
  return (
    <>
      <ProgressItemName name={item.name} url={item.url} isActive={item.active} />
      {withBar && <ProgressBar percentComplete={item.percentComplete} />}
    </>
  );
}

ProgressItem.defaultProps = {
  withBar: true,
};

ProgressItem.propTypes = {
  item: itemPropType.isRequired,
  withBar: PropTypes.bool,
};

function Progress({ items }) {
  return (
    <div className="w-100">
      <div className="d-flex align-items-center gap-3 progress-items">
        {items.map((item, idx) => (
          <ProgressItem key={nanoid()} item={item} withBar={idx !== items.length - 1} />
        ))}
      </div>
    </div>
  );
}

Progress.propTypes = {
  items: PropTypes.arrayOf(itemPropType).isRequired,
};

export default Progress;
