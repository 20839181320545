import * as Sentry from "@sentry/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useGetBomQuery } from "../../../features/api/boms";
import { addErrors } from "../../../features/app/appStateSlice";
import {
  bomIdSelector,
  setBomId,
  setComponentQuestions,
  setComponents,
  setDefaultCurrency,
  setFinishedGood,
  setFinishedGoodQuestions,
} from "../../../features/bomWizard/bomWizardSlice";

export default function useFinishBom() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [bomQueryBody, setBomQueryBody] = useState(null);
  const { data: bomDetail, isError, error } = useGetBomQuery(bomQueryBody, { skip: !bomQueryBody });
  const navigate = useNavigate();
  const bomId = useSelector(bomIdSelector);

  const handleFinish = useCallback(
    (bom) => {
      setIsLoading(true);
      dispatch(setBomId(bom.BOMID));
      setIsLoading(false);
    },
    [dispatch],
  );

  useEffect(() => {
    if (bomId) {
      const [username, timeStamp] = bomId.split("#");
      setBomQueryBody({
        username,
        bomId: timeStamp,
      });
    } else {
      setBomQueryBody(null);
    }

    return () => {
      setBomQueryBody(null);
    };
  }, [bomId]);

  useEffect(
    () => () => {
      setIsLoading(false);
    },
    [],
  );

  useEffect(() => {
    if (bomId && bomDetail) {
      dispatch(setDefaultCurrency(bomDetail.FG.Currency));
      dispatch(setFinishedGood(bomDetail.FG));
      dispatch(setFinishedGoodQuestions({ Qs: bomDetail.FG_Qs }));
      dispatch(setComponents(bomDetail.Components));
      dispatch(setComponentQuestions({ Qs: bomDetail.Comp_Qs }));
      const bomState = bomDetail.BOM_State;
      if (bomState === "FG_QUALIFYING") {
        navigate("/dashboard/create-bill-of-material/fg-questions/");
      } else if (bomState === "FG_COMPLETE") {
        navigate("/dashboard/create-bill-of-material/component-details/");
      } else if (bomState === "COMPS_QUALIFYING") {
        navigate("/dashboard/create-bill-of-material/comp-questions/");
      } else if (bomState === "COMPS_COMPLETE") {
        navigate("/dashboard/create-bill-of-material/submission/");
      } else {
        dispatch(addErrors(["This BOM is in an invalid state. Please refresh the page."]));
        Sentry.captureMessage(`BOM ${bomId} has invalid state ${bomState}.`);
      }
    }
  }, [dispatch, bomDetail, navigate, bomId]);

  useEffect(() => {
    if (isError) {
      dispatch(addErrors([JSON.stringify(error.data || "Couldn't retrieve BOM details. Please try again.")]));
    }
  }, [isError, error, dispatch]);

  return useMemo(() => ({ isLoading, handleFinish }), [isLoading, handleFinish]);
}
