import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { useCreateComponentsMutation } from "../../../features/api/boms";
import { bomIdSelector, componentsSelector, isCloning as isCloningSelector } from "../../../features/bomWizard/bomWizardSlice";
import useBom from "../../../hooks/useBom";

export default function useComponentsForm() {
  const storedComponents = useSelector(componentsSelector);
  const isCloning = useSelector(isCloningSelector);
  const bomId = useSelector(bomIdSelector);
  const [initialValues, setInitialValues] = useState(null);
  const [loading, setLoading] = useState(true);
  const [createComponents, { isError, data: componentData }] = useCreateComponentsMutation();
  const navigate = useNavigate();
  const { bom } = useBom(bomId);

  useEffect(() => {
    let components;
    if (isCloning) {
      components = storedComponents;
    } else if (bom) {
      components = bom.Components;
    } else {
      components = [];
    }
    if (components.length > 0) {
      setInitialValues({
        components: components.map((comp) => ({
          partNumber: comp.Part,
          hts: comp.Hts,
          description: comp.Desc,
          category: comp.Category,
          qty: comp.Qty,
          units: comp.Unit,
          cost: comp.Cost,
          origin: comp.Origin,
          originating: comp.Originating,
        })),
      });
    } else {
      setInitialValues({ components: [] });
    }
    setLoading(false);

    return () => {
      setLoading(true);
    };
  }, [bom, isCloning, storedComponents]);

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      setSubmitting(true);
      let existingLineItems;
      if (bom && bom.Components && Array.isArray(bom.Components) && bom.Components.length > 0) {
        existingLineItems = bom.Components.map((_, idx) => idx);
      }
      const componentsToSubmit = values.components.filter((_, idx) => (existingLineItems ? existingLineItems.indexOf(idx) === -1 : true));
      const components = componentsToSubmit.map((component, idx) => ({
        LineItem: existingLineItems ? existingLineItems.length + (idx + 1) : idx + 1,
        Part: component.partNumber,
        Hts: component.category === "Material" ? component.hts.replaceAll(".", "") : "0",
        Desc: component.description,
        Category: component.category,
        Qty: component.qty,
        Unit: component.units,
        Cost: component.cost,
        Origin: component.origin,
        Originating: component.originating,
      }));
      let errorMsg;

      for (let i = 0; i < components.length - 1; i += 1) {
        const component = components[i];
        const { Category: category, Hts: hts, Origin: origin } = component;
        if (category === "Material" && hts === "") {
          errorMsg = "Please provide HTS for every Material component.";
          break;
        }

        if (category === "Material" && origin === "") {
          errorMsg = "Please specify the origin of all Material components.";
          break;
        }
      }

      if (errorMsg) {
        Swal.fire({
          title: "Error!",
          text: errorMsg,
          icon: "error",
        }).then(() => {
          setSubmitting(false);
        });
      } else {
        const requestBody = {
          BOMID: bomId,
          Components: components,
        };
        createComponents(requestBody).finally(() => {
          setSubmitting(false);
        });
      }
    },
    [bomId, createComponents, bom],
  );

  useEffect(() => {
    if (componentData) {
      if (componentData.Status === "COMPS_COMPLETE") {
        navigate("/dashboard/create-bill-of-material/submission/");
      } else {
        navigate("/dashboard/create-bill-of-material/comp-questions/");
      }
    }
  }, [componentData, navigate]);

  useEffect(() => {
    if (isError) {
      Swal.fire({
        title: "Error!",
        text: "Unable to save components. Please try again.",
        icon: "error",
      });
    }
  }, [isError]);

  return useMemo(() => ({ initialValues, handleSubmit, loading }), [initialValues, handleSubmit, loading]);
}
