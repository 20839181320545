/* eslint react/prop-types: 0 */
import "flatpickr/dist/themes/light.css";
import { useFormikContext } from "formik";
import React, { useCallback, useMemo } from "react";
import Flatpickr from "react-flatpickr";

function FormikFlatpickr({ options, name, className }) {
  const { setFieldValue } = useFormikContext();
  const defaultOptions = useMemo(
    () => ({
      dateFormat: "m-d-Y",
      defaultDate: new Date(),
    }),
    [],
  );

  const handleChange = useCallback(
    ([dateObj]) => {
      setFieldValue(name, dateObj.toISOString());
    },
    [setFieldValue, name],
  );

  return <Flatpickr onChange={handleChange} className={className} options={{ ...defaultOptions, ...options }} />;
}

FormikFlatpickr.defaultProps = {
  options: {},
  className: "form-control",
};

export default FormikFlatpickr;
