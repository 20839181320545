import { useMemo } from "react";
import { useSelector } from "react-redux";

import { bomIdSelector } from "../../../features/bomWizard/bomWizardSlice";
import useBom from "../../../hooks/useBom";

export default function useComponentDetails() {
  const bomId = useSelector(bomIdSelector);
  const { bom, loading } = useBom(bomId);

  return useMemo(() => ({ bom, loading }), [bom, loading]);
}
