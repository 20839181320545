import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { QUALIFICATION_HISTORY_PAGE } from "../../../constants";
import { useListBomsQuery } from "../../../features/api/boms";
import { clearState } from "../../../features/bomWizard/bomWizardSlice";
import { setCurrentUnparsedBOM, setHeaders } from "../../../features/fileImport/fileImportSlice";
import { setCurrentPage } from "../../../features/ui/navigationSlice";

export default function useDashboard() {
  const [pageTitle, setPageTitle] = useState("Your Boms");
  const { data: boms } = useListBomsQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    if (boms) {
      if (boms.open.length > 0 || boms.completed.length > 0) {
        setPageTitle("Qualification History");
      } else {
        setPageTitle("Your Boms");
      }
    } else {
      setPageTitle("Your Boms");
    }

    return () => setPageTitle("Your Boms");
  }, [boms]);

  useEffect(() => {
    dispatch(clearState());
    dispatch(setCurrentUnparsedBOM(null));
    dispatch(setHeaders(null));
  });

  useEffect(() => {
    dispatch(setCurrentPage(QUALIFICATION_HISTORY_PAGE));

    return () => {
      setCurrentPage(null);
    };
  }, [dispatch]);

  return useMemo(
    () => ({
      pageTitle,
    }),
    [pageTitle],
  );
}
