import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

import {
  currencySelector,
  currentUnparsedBOMSelector,
  mappedComponentHeaders,
  mappedFinishedGoodHeaders,
  setSubmittingToApi,
} from "../../../../features/fileImport/fileImportSlice";
import { validateComponents, validateFinishedGood } from "../utils";
import useCreateBOM from "./useCreateBOM";

const INITIAL_FINISHED_GOOD_VALIDATION = {
  done: false,
  isValid: null,
  message: null,
  validData: null,
};

const INITIAL_COMPONENTS_VALIDATION = {
  done: false,
  isValid: null,
  message: null,
};

export default function useContinue() {
  const { createBOM } = useCreateBOM();
  const currency = useSelector(currencySelector);
  const dispatch = useDispatch();
  const [csvFinishedGood, setCsvFinishedGood] = useState(null);
  const [csvComponents, setCsvComponents] = useState(null);
  const currentUnparsedBOM = useSelector(currentUnparsedBOMSelector);
  const matchedFinishedGoodHeaders = useSelector(mappedFinishedGoodHeaders);
  const matchedComponentHeaders = useSelector(mappedComponentHeaders);
  const [finishedGoodValidation, setFinishedGoodValidation] = useState(INITIAL_FINISHED_GOOD_VALIDATION);
  const [componentsValidation, setComponentsValidation] = useState(INITIAL_COMPONENTS_VALIDATION);

  useEffect(() => {
    if (currentUnparsedBOM) {
      setCsvFinishedGood(currentUnparsedBOM.find((row) => row.Item === "FG"));
      setCsvComponents(currentUnparsedBOM.filter((row) => row.item === "Component"));
    }

    return () => {
      setCsvFinishedGood(null);
      setCsvComponents(null);
    };
  }, [currentUnparsedBOM]);

  const handleContinue = useCallback(() => {
    dispatch(setSubmittingToApi(true));
    const fg = {
      partNumber: csvFinishedGood[matchedFinishedGoodHeaders.partNumber],
      hts: csvFinishedGood[matchedFinishedGoodHeaders.hts],
      description: csvFinishedGood[matchedFinishedGoodHeaders.description],
      transactionValue: csvFinishedGood[matchedFinishedGoodHeaders.transactionValue],
      netCost: csvFinishedGood[matchedFinishedGoodHeaders.netCost],
      totalCost: csvFinishedGood[matchedFinishedGoodHeaders.totalCost],
      origin: csvFinishedGood[matchedFinishedGoodHeaders.origin],
    };
    validateFinishedGood(fg)
      .then((validFg) => {
        setFinishedGoodValidation({ done: true, isValid: true, validData: validFg });
      })
      .catch((err) => {
        setFinishedGoodValidation({ done: true, isValid: false, message: err.message });
      });
  }, [dispatch, csvFinishedGood, matchedFinishedGoodHeaders]);

  useEffect(() => {
    if (finishedGoodValidation && finishedGoodValidation.done) {
      if (finishedGoodValidation.isValid) {
        validateComponents(csvComponents, matchedComponentHeaders)
          .then(() => {
            setComponentsValidation({
              done: true,
              isValid: true,
            });
          })
          .catch((err) => {
            setComponentsValidation({
              done: true,
              isValid: false,
              message: err.message,
            });
          });
      } else {
        Swal.fire({
          title: "Error!",
          text: `Invalid finished good. ${finishedGoodValidation.message}`,
          icon: "error",
        }).then(() => {
          dispatch(setSubmittingToApi(false));
        });
      }
    }

    return () => {
      setComponentsValidation(INITIAL_COMPONENTS_VALIDATION);
    };
  }, [finishedGoodValidation, dispatch, csvComponents, matchedComponentHeaders]);

  useEffect(() => {
    if (componentsValidation && componentsValidation.done) {
      if (componentsValidation.isValid) {
        const fgRequest = {
          Part: finishedGoodValidation.validData.partNumber,
          Desc: finishedGoodValidation.validData.description,
          Hts: finishedGoodValidation.validData.hts,
          Value: finishedGoodValidation.validData.transactionValue,
          NetCost: finishedGoodValidation.validData.netCost,
          TotalCost: finishedGoodValidation.validData.totalCost,
          Origin: finishedGoodValidation.validData.origin,
          Currency: currency,
        };
        createBOM(fgRequest);
      } else {
        Swal.fire({
          title: "Error!",
          text: componentsValidation.message,
          icon: "error",
        }).then(() => {
          dispatch(setSubmittingToApi(false));
        });
      }
    }
  }, [componentsValidation, finishedGoodValidation, currency, createBOM, dispatch]);

  return useMemo(() => ({ handleContinue }), [handleContinue]);
}
