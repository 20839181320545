import PropTypes from "prop-types";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { bomIdSelector } from "../../../features/bomWizard/bomWizardSlice";
import useBOMStateRequired from "../../../hooks/useBOMStateRequired";
import useBom from "../../../hooks/useBom";

const ComponentQuestionsContext = createContext(null);

function ComponentQuestionsProvider({ children }) {
  const bomId = useSelector(bomIdSelector);
  const { bom, loading: bomLoading } = useBom(bomId);
  const [questions, setQuestions] = useState(null);
  useBOMStateRequired(bom?.BOM_State, "COMPS_QUALIFYING");

  const componentAndQuestions = useMemo(() => {
    const result = [];
    if (questions) {
      bom.Components.forEach((component) => {
        const currentQuestions = questions.filter((q) => {
          const hts = q.RuleID.split("#")[0];
          return hts === component.Hts;
        });
        if (currentQuestions && currentQuestions.length > 0) {
          result.push({ component, questions: currentQuestions });
        }
      });
    }
    return result;
  }, [questions, bom]);

  useEffect(() => {
    if (bom && Array.isArray(bom.Comp_Qs) && bom.Comp_Qs.length > 0) {
      setQuestions([...bom.Comp_Qs]);
    } else {
      setQuestions(null);
    }

    return () => {
      setQuestions(null);
    };
  }, [bom]);
  const value = useMemo(() => ({ bom, bomLoading, questions, componentAndQuestions }), [bom, bomLoading, questions, componentAndQuestions]);
  return <ComponentQuestionsContext.Provider value={value}>{children}</ComponentQuestionsContext.Provider>;
}

ComponentQuestionsProvider.defaultProps = {
  children: "",
};

ComponentQuestionsProvider.propTypes = {
  children: PropTypes.node,
};

export { ComponentQuestionsProvider };

export function useComponentQuestionsContext() {
  return useContext(ComponentQuestionsContext);
}
