import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

import { useCreateComponentsMutation } from "../../../../features/api/boms";
import {
  currentUnparsedBOMSelector,
  mappedComponentHeaders as mappedComponentHeadersSelector,
  newBomIdSelector,
  setSubmittingToApi,
} from "../../../../features/fileImport/fileImportSlice";
import useBom from "../../../../hooks/useBom";

export default function useCreateComponents() {
  const dispatch = useDispatch();
  const newBomId = useSelector(newBomIdSelector);
  const { bom } = useBom(newBomId);
  const currentUnparsedBOM = useSelector(currentUnparsedBOMSelector);
  const [components, setComponents] = useState(null);
  const mappedComponentHeaders = useSelector(mappedComponentHeadersSelector);
  const [createComponents, { isError: createComponentsIsError }] = useCreateComponentsMutation();

  useEffect(() => {
    if (currentUnparsedBOM) {
      setComponents(currentUnparsedBOM.filter((row) => row.Item === "Component"));
    }
  }, [currentUnparsedBOM]);

  useEffect(() => {
    if (bom && components) {
      if (bom.BOM_State === "FG_COMPLETE") {
        const comps = components.map((comp, idx) => ({
          LineItem: idx + 1,
          Part: comp[mappedComponentHeaders.partNumber],
          Desc: comp[mappedComponentHeaders.description],
          Hts: comp[mappedComponentHeaders.category] === "Material" ? String(comp[mappedComponentHeaders.hts]) : "0",
          Qty: String(comp[mappedComponentHeaders.qty]),
          Unit: comp[mappedComponentHeaders.units],
          Cost: String(comp[mappedComponentHeaders.unitCost]),
          Origin: comp[mappedComponentHeaders.origin],
          Originating: comp[mappedComponentHeaders.originating] || "N",
          Category: comp[mappedComponentHeaders.category],
        }));
        createComponents({ BOMID: bom.BOMID, Components: comps });
      }
    }
  }, [bom, components, createComponents, dispatch, mappedComponentHeaders]);

  useEffect(() => {
    if (createComponentsIsError) {
      Swal.fire({
        title: "error",
        text: "Unable to add components. Please try again.",
        icon: "error",
      }).then(() => {
        dispatch(setSubmittingToApi(false));
      });
    }
  }, [createComponentsIsError, dispatch]);
}
