import { nanoid } from "nanoid";
import PropTypes from "prop-types";
import React from "react";

function FormError({ error }) {
  return (
    <div key={nanoid()} className="invalid-feedback">
      {error}
    </div>
  );
}

FormError.defaultProps = {
  error: "",
};

FormError.propTypes = {
  error: PropTypes.string,
};

export default FormError;
