import { Field, useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";

import countryCodes from "../../data/country-codes.json";
import FormError from "../FormError";

function CountryCodesSelector({ label, name, extraClasses }) {
  const { errors } = useFormikContext();

  return (
    <>
      {label && (
        <label htmlFor={`select${name}`} className="form-label">
          {label}
        </label>
      )}
      <Field name={name} as="select" className={`form-select ${errors && errors[name] ? "is-invalid" : ""} ${extraClasses}`}>
        {countryCodes.map((countryCode) => (
          <option key={countryCode} value={countryCode}>
            {countryCode}
          </option>
        ))}
      </Field>
      {errors && errors[name] && <FormError error={errors[name]} />}
    </>
  );
}

CountryCodesSelector.defaultProps = {
  label: null,
  extraClasses: "",
};

CountryCodesSelector.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  extraClasses: PropTypes.string,
};

export default CountryCodesSelector;
