import { Link } from "react-router-dom";

import useContinueAction from "../../components/BOMCard/components/Header/useContinueAction";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { useBomContext } from "./BOMContext";

export default function UnsubmittedActions() {
  const { bom } = useBomContext();
  const { handleContinue } = useContinueAction();

  if (!bom) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div>
      <div className="d-flex justify-content-center align-items-center gap-2">
        <Link className="btn btn-outline-primary fw-bold text-uppercase" to="/dashboard">
          Cancel & Go Back
        </Link>
        <button onClick={() => handleContinue(bom)} type="button" className="btn btn-primary fw-bold text-uppercase">
          Continue
        </button>
      </div>
    </div>
  );
}
