/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field, Form, Formik } from "formik";
import { nanoid } from "nanoid";
import React from "react";
import { Link } from "react-router-dom";

import FormError from "../../../components/FormError";
import buildHtsValue from "../../../utils/buildHtsValue";
import unpackHts from "../../../utils/unpackHts";
import ProgressSteps from "../components/ProgressSteps";
import { bomSchema } from "./schema";
import useNewBOM from "./useNewBOM";

export default function NewBOM() {
  const { finishedGood, error, isError, onSubmit, bomCreationError } = useNewBOM();

  return (
    <>
      <div className="d-flex justify-content-center my-5">
        <ProgressSteps activeIdx={0} />
      </div>
      <h1 className="mb-5 text-center">Create a new BOM</h1>
      <div className="row">
        <div className="col-lg-2" />
        <div className="col-lg-8">
          {error && <p className="alert alert-danger">{error}</p>}
          {isError && <p className="alert alert-danger">{JSON.stringify(bomCreationError.data || "Unknown error.")}</p>}
          <Formik
            validateOnChange={false}
            validateOnBlur
            validationSchema={bomSchema}
            initialValues={{
              Part: finishedGood?.Part || "",
              Desc: finishedGood?.Desc || "",
              Producer: finishedGood?.Producer || "",
              Hts: finishedGood ? unpackHts(finishedGood.Hts) : "",
              Value: finishedGood?.Value || "",
              Origin: finishedGood?.Origin || "US",
              Currency: "USD",
            }}
            onSubmit={onSubmit}
          >
            {({
              errors,
              touched,
              isSubmitting,
              values,
              /* and other goodies */
            }) => (
              <Form className="mb-5">
                <div className="form-group mb-4">
                  <label className="form-label w-100" htmlFor="selectCurrency">
                    Currency
                    <Field className={`form-select mt-2 ${touched.currency && errors.currency ? "is-invalid" : ""}`} name="Currency" as="select" id="selectCurrency">
                      <option value="USD">USD</option>
                      <option value="MXP">MXP</option>
                      <option value="CAD">CAD</option>
                    </Field>
                    <FormError error={errors.currency} />
                  </label>
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="inputPartNumber" className="form-label w-100">
                    Part Number
                    <Field
                      placeholder="ABCD001"
                      id="inputPartNumber"
                      type="text"
                      name="Part"
                      className={`form-control mt-2 ${touched.Part && errors.Part ? "is-invalid" : ""}`}
                    />
                    <FormError error={errors.Part} />
                  </label>
                </div>

                <div className="form-group mb-4">
                  <label htmlFor="inputDescription" className="form-label w-100">
                    Description
                    <Field
                      name="Desc"
                      id="inputDescription"
                      placeholder="Woven Fabric"
                      type="text"
                      className={`form-control mt-2 ${touched.Desc && errors.Desc ? "is-invalid" : ""}`}
                    />
                    <FormError error={errors.Desc} />
                  </label>
                </div>

                <div className="form-group mb-4">
                  <label htmlFor="inputProducer" className="form-label w-100">
                    Producer
                    <Field
                      name="Producer"
                      placeholder="XYZ Corp."
                      id="inputProducer"
                      type="text"
                      className={`form-control mt-2 ${touched.Producer && errors.Producer ? "is-invalid" : ""}`}
                    />
                    <FormError error={errors.Producer} />
                  </label>
                </div>

                <div className="form-group mb-4">
                  <label htmlFor="inputUsHts" className="form-label w-100">
                    Hts
                    <Field
                      className={`form-control mt-2 ${touched.Hts && errors.Hts ? "is-invalid" : ""}`}
                      name="Hts"
                      type="text"
                      placeholder="0000.00.0000"
                      id="inputUsHts"
                      value={buildHtsValue(values.Hts)}
                    />
                    <FormError error={errors.Hts} />
                  </label>
                </div>

                <div className="form-group mb-4">
                  <label htmlFor="inputValue" className="form-label w-100">
                    Value ({values.Currency})
                    <Field placeholder="10" type="number" name="Value" className={`form-control mt-2 ${touched.Value && errors.Value ? "is-invalid" : ""}`} />
                    <FormError error={errors.Value} />
                  </label>
                </div>

                <div className="form-group mb-4">
                  <label htmlFor="selectOrigin" className="form-label w-100">
                    Country of Origin (COO)
                    <Field as="select" name="Origin" className="form-select mt-2">
                      {["US", "MX", "CA"].map((countryCode) => (
                        <option key={nanoid()} value={countryCode}>
                          {countryCode}
                        </option>
                      ))}
                    </Field>
                    <FormError error={errors.Origin} />
                  </label>
                </div>
                <div className="d-flex">
                  <Link to="/dashboard" className="btn btn-secondary me-2">
                    Cancel
                  </Link>
                  <button type="submit" className="btn btn-success" disabled={isSubmitting}>
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="col-lg-2" />
      </div>
    </>
  );
}
