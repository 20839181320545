import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { useCreateBOMMutation } from "../../../../features/api/boms";
import { finishedGoodSelector, setBomId } from "../../../../features/bomWizard/bomWizardSlice";

export default function useFinishedGoodForm() {
  const [createBOM, { isError, error, data: newBom }] = useCreateBOMMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const finishedGood = useSelector(finishedGoodSelector);

  const initialValues = useMemo(
    () => ({
      currency: finishedGood?.Currency || "USD",
      partNumber: finishedGood?.Part || "",
      description: finishedGood?.Desc || "",
      hts: finishedGood?.Hts || "",
      value: finishedGood && finishedGood.Value && finishedGood.Value !== "0" ? finishedGood.Value : "",
      netCost: finishedGood && finishedGood.NetCost && finishedGood.NetCost !== "0" ? finishedGood.NetCost : "",
      totalCost: finishedGood && finishedGood.TotalCost && finishedGood.TotalCost !== "0" ? finishedGood.TotalCost : "",
      coo: finishedGood?.Origin || "US",
    }),
    [finishedGood],
  );

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      setSubmitting(true);
      let errorMsg;
      const { value, netCost } = values;
      if (value === "" && netCost === "") {
        errorMsg = "Please provide either the transaction value, the net cost of your finished good, or both of them.";
      }

      if (errorMsg) {
        Swal.fire({
          title: "Error!",
          text: errorMsg,
          icon: "error",
        }).then(() => {
          setSubmitting(false);
        });
      } else {
        const requestBody = {
          Part: values.partNumber,
          Desc: values.description,
          Hts: values.hts.replaceAll(".", ""),
          Value: String(values.value),
          NetCost: String(values.netCost),
          TotalCost: String(values.totalCost),
          Origin: values.coo,
          Currency: values.currency,
        };
        createBOM(requestBody).finally(() => {
          setSubmitting(false);
        });
      }
    },
    [createBOM],
  );

  useEffect(() => {
    if (isError && error) {
      Swal.fire({
        title: "Error!",
        text: `Can't create BOM. ${JSON.stringify(error.data || "Unknown error")}.`,
      });
    }
  }, [isError, error]);

  useEffect(() => {
    if (newBom) {
      dispatch(setBomId(newBom.BOMID));
      if (newBom.Status === "FG_COMPLETE") {
        navigate("/dashboard/create-bill-of-material/component-details/");
      } else {
        navigate("/dashboard/create-bill-of-material/fg-questions/");
      }
    }
  }, [newBom, navigate, dispatch]);

  return useMemo(() => ({ initialValues, handleSubmit }), [initialValues, handleSubmit]);
}
