import { Field, useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import FormError from "../../../components/FormError";

function HTSField({ idx, disabled }) {
  const { setFieldValue, errors, touched, values } = useFormikContext();
  const [error, setError] = useState(null);
  const [hts, setHts] = useState("");
  const [isMaterial, setIsMaterial] = useState(false);
  const [isDisabled, setIsDisabled] = useState(disabled);

  useEffect(() => {
    if (errors?.components && touched?.components) {
      if (errors.components[idx] && touched.components[idx]) {
        if (errors.components[idx].hts && touched.components[idx].hts) {
          setError(errors.components[idx].hts);
        } else {
          setError(null);
        }
      } else {
        setError(null);
      }
    } else {
      setError(null);
    }
  }, [errors.components, touched.components, idx]);

  useEffect(() => {
    if (values?.components[idx] && values?.components[idx].hts) {
      setHts((currentHts) => {
        const oldHts = values.components[idx].hts;
        if (oldHts.length === 4 || oldHts.length === 7) {
          if (currentHts[currentHts.length - 1] !== ".") {
            return `${oldHts}.`;
          }
        } else if (oldHts.length >= 9) {
          const numberOfDots = oldHts.match(/\./g);
          if (numberOfDots === null) {
            const newHts = `${oldHts.slice(0, 4)}.${oldHts.slice(4, 6)}.${oldHts.slice(6)}`;
            return oldHts.length === 9 ? `0${newHts}` : newHts;
          }
        }
        return oldHts;
      });
    }

    if (values && values.components && values.components[idx] && values.components[idx].category) {
      const { category } = values.components[idx];
      if (category === "Material") {
        setIsMaterial(true);
      } else {
        setIsMaterial(false);
      }
    }

    return () => {
      setHts("");
      setIsMaterial(false);
    };
  }, [values, idx]);

  useEffect(() => {
    if (hts) {
      setFieldValue(`components.${idx}.hts`, hts);
    }
  }, [idx, hts, setFieldValue]);

  useEffect(() => {
    if (disabled) {
      setIsDisabled(true);
    } else if (isMaterial) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

    return () => {
      setIsDisabled(disabled);
    };
  }, [disabled, isMaterial]);

  return (
    <>
      <label htmlFor={`inputHts${idx}`} className="form-label">
        HTS
      </label>
      <Field
        disabled={isDisabled}
        name={`components.${idx}.hts`}
        id={`inputHts${idx}`}
        className={`form-control ${error ? "is-invalid" : ""}`}
        placeholder="Ex. 0000.00.0000"
      />
      {error && <FormError error={error} />}
    </>
  );
}

HTSField.defaultProps = {
  disabled: false,
};

HTSField.propTypes = {
  idx: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

export default HTSField;
