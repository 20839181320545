import { initializePaddle } from "@paddle/paddle-js";
import { dispatch } from "d3";
import PropTypes from "prop-types";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";

import { useGetCustomerQuery } from "../../features/api/customers";
import { useListPricesQuery } from "../../features/api/payments";
import { addErrors } from "../../features/app/appStateSlice";
import { findPrice } from "../../utils/payments";

const UpgradeContext = createContext(null);

function UpgradeContextProvider({ children }) {
  const { data: priceList } = useListPricesQuery();
  const { data: customer } = useGetCustomerQuery();
  const [checkoutIsOpen, setCheckoutIsOpen] = useState(false);
  const [paddle, setPaddle] = useState(null);
  const [purchaseAmount, setPurchaseAmount] = useState(1);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [currentUnitPrice, setCurrentUnitPrice] = useState(0);
  const [total, setTotal] = useState(0);

  const startCheckout = useCallback(() => {
    const productPrice = findPrice(priceList.prices, purchaseAmount);
    setSelectedPrice(productPrice);
    if (productPrice) {
      paddle.Checkout.open({
        items: [{ priceId: productPrice.id, quantity: Number(purchaseAmount) }],
        customer: {
          email: customer.email,
          address: { firstLine: customer.street_address, city: customer.city, region: customer.state, postalCode: customer.zip_code, countryCode: customer.country },
          business: {
            name: customer.company_name,
            taxIdentifier: customer.tax_id,
          },
        },
        settings: { allowLogout: false },
      });
      setCheckoutIsOpen(true);
    } else {
      dispatch(addErrors(["We encountered an error. Please reload the page."]));
    }
  }, [paddle, priceList, purchaseAmount, customer]);

  useEffect(() => {
    if (typeof currentUnitPrice !== "undefined" && typeof purchaseAmount !== "undefined") setTotal(currentUnitPrice * purchaseAmount);
  }, [currentUnitPrice, purchaseAmount]);

  useEffect(() => {
    if (priceList) {
      const price = findPrice(priceList.prices, purchaseAmount);
      if (price) {
        setCurrentUnitPrice(Number(price.price) / 100);
      } else {
        setCurrentUnitPrice(0);
      }
    }
  }, [purchaseAmount, priceList]);

  useEffect(() => {
    initializePaddle({
      environment: process.env.REACT_APP_PADDLE_ENVIRONMENT,
      token: process.env.REACT_APP_PADDLE_TOKEN,
      checkout: { settings: { displayMode: "overlay", allowLogout: false } },
      eventCallback: (data) => {
        if (data.name === "checkout.completed") {
          window.location.replace(process.env.REACT_APP_PADDLE_CHECKOUT_SUCCESS_URL);
        }
      },
    }).then((paddleInstance) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      } else {
        Swal.fire({
          title: "Error!",
          text: "Failed to start checkout. Please reload the page.",
          icon: "error",
        });
      }
    });

    return () => {
      setPaddle(null);
    };
  }, []);

  useEffect(() => {
    if (checkoutIsOpen && selectedPrice) {
      paddle.Checkout.updateItems([{ priceId: selectedPrice.id, quantity: Number(purchaseAmount) }]);
    } else if (checkoutIsOpen && !selectedPrice) {
      paddle.Checkout.close();
      setCheckoutIsOpen(false);
    }

    return () => {
      setCheckoutIsOpen(false);
    };
  }, [selectedPrice, checkoutIsOpen, paddle, purchaseAmount]);

  const value = useMemo(
    () => ({ total, currentUnitPrice, checkoutIsOpen, startCheckout, purchaseAmount, setPurchaseAmount }),
    [checkoutIsOpen, startCheckout, purchaseAmount, setPurchaseAmount, total, currentUnitPrice],
  );
  return <UpgradeContext.Provider value={value}>{children}</UpgradeContext.Provider>;
}

UpgradeContextProvider.defaultProps = {
  children: null,
};

UpgradeContextProvider.propTypes = {
  children: PropTypes.node,
};

export { UpgradeContextProvider };

export function useUpgradeContext() {
  return useContext(UpgradeContext);
}
