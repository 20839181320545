import PropTypes from "prop-types";
import React from "react";

import HeaderItems from "./components/HeaderItems/HeaderItems";

function PageHeader({ title, border }) {
  return (
    <header className={`d-flex align-items-center justify-content-between px-4 pb-3 ${border ? "border-bottom" : ""}`}>
      <h1>{title}</h1>
      <HeaderItems />
    </header>
  );
}

PageHeader.defaultProps = {
  border: false,
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  border: PropTypes.bool,
};

export default PageHeader;
