import { useEffect, useMemo, useState } from "react";

import { useGetBomQuery } from "../features/api/boms";

export default function useBom(bomId) {
  const [userName, setUserName] = useState(null);
  const [timeStamp, setTimeStamp] = useState(null);
  const [loading, setLoading] = useState(true);
  const { data: bom, isError, isLoading: bomIsLoading } = useGetBomQuery({ username: userName, bomId: timeStamp }, { skip: !userName && !timeStamp });

  useEffect(() => {
    if (bomId) {
      const [part1, part2] = bomId.split("#");
      setUserName(part1);
      setTimeStamp(part2);
    }

    return () => {
      setUserName(null);
      setTimeStamp(null);
    };
  }, [bomId]);

  useEffect(() => {
    if (bom && !bomIsLoading) {
      setLoading(false);
    } else if (!bom && !bomIsLoading) {
      setLoading(false);
    } else {
      setLoading(true);
    }

    return () => {
      setLoading(true);
    };
  }, [bom, bomIsLoading]);

  return useMemo(
    () => ({
      bom,
      loading,
      isError,
    }),
    [loading, bom, isError],
  );
}
