import { useEffect, useMemo, useState } from "react";

import { useListBomsQuery } from "../features/api/boms";

export default function useHasBoms() {
  const [hasBoms, setHasBoms] = useState(false);
  const { data: boms, isLoading } = useListBomsQuery();

  useEffect(() => {
    setHasBoms(boms && (boms.open.length > 0 || boms.completed.length > 0));

    return () => setHasBoms(false);
  }, [boms]);

  return useMemo(() => ({ hasBoms, isLoading }), [hasBoms, isLoading]);
}
