import PropTypes from "prop-types";
import React from "react";

import useBom from "../../hooks/useBom";
import { htsDisplay } from "../../utils/bom";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

function FinishedGoodDetailsCard({ bomId }) {
  const { bom, loading } = useBom(bomId);

  let body;

  if (loading || typeof bomId === "undefined") {
    body = (
      <div className="d-flex justify-content-center align-items-center py-5">
        <LoadingSpinner />
      </div>
    );
  } else if (bom) {
    body = (
      <div className="d-flex h-100 flex-column justify-content-between">
        <div className="mb-2">
          <div className="d-flex justify-content-between align-content-center">
            <div style={{ width: "max-content" }} className="bg-light-0 rounded-pill text-center py-1 px-3 fw-bold mb-3">
              BOM Details
            </div>
            <p className="m-0 text-light-0">
              <b>Currency:</b> {bom.FG.Currency}
            </p>
          </div>

          <h4 className="text-light-0">{bom.FG.Part}</h4>
          <p className="text-light">{bom.FG.Desc}</p>
        </div>
        <div className="d-grid gap-1" style={{ gridTemplateColumns: "repeat(3, 1fr)", gridTemplateRows: "1fr 1fr" }}>
          <p className="m-0 text-light">COO | {bom.FG.Origin}</p>
          <p style={{ justifySelf: "center" }} className="m-0 text-light">
            BOM ID | {bom.BOMID}
          </p>
          <p style={{ justifySelf: "end" }} className="m-0 text-light">
            HTS | {htsDisplay(bom.FG.Hts)}
          </p>
          <p className="m-0 text-light" style={{ alignSelf: "end" }}>
            Transaction Value | {bom.FG.Value}
          </p>
          <p style={{ justifySelf: "center", alignSelf: "end" }} className="m-0 text-light">
            Net Cost | {bom.FG.NetCost}
          </p>
          <p style={{ justifySelf: "end", alignSelf: "end" }} className="m-0 text-light">
            Total Cost | {bom.FG.TotalCost}
          </p>
        </div>
      </div>
    );
  }
  return (
    <div style={{ minHeight: "200px" }} className="bom-wizard__finished-good-details-card p-3 bg-dark rounded">
      {body}
    </div>
  );
}

FinishedGoodDetailsCard.propTypes = {
  bomId: PropTypes.string.isRequired,
};

export default FinishedGoodDetailsCard;
