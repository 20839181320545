import { Form, Formik } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import { array, number, object, string } from "yup";

import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import ComponentsFieldArray from "./ComponentsFieldArray";
import useComponentsForm from "./useComponentsForm";

const componentsSchema = object().shape({
  components: array().of(
    object().shape({
      partNumber: string(),
      hts: string()
        .min(12, "Hts must contain at least 10 digits.")
        .max(12, "Hts must contain no more than 10 digits.")
        .test("USHTSNumbersOnly", "USHTS must contain numbers only.", (value) => !/[a-zA-Z]/.test(value)),
      description: string().required("Description is required."),
      qty: number().positive("Quantity must be greater than zero.").required("Quantity is required."),
      units: string(),
      cost: number().positive("Cost must be greater than zero.").required("Cost is required."),
      origin: string(),
      originating: string(),
    }),
  ),
});

function ComponentsForm() {
  const { initialValues, handleSubmit, loading } = useComponentsForm();

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Formik enableReinitialize validationSchema={componentsSchema} initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <Form>
          <div className="mb-5">
            <ComponentsFieldArray />
          </div>
          <div className="d-flex justify-content-center align-items-center gap-2">
            <Link className="btn btn-outline-primary fw-bold text-uppercase" to="/dashboard/">
              Cancel & Go back
            </Link>
            <button type="submit" className="btn btn-primary fw-bold text-uppercase" disabled={isSubmitting}>
              Continue
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ComponentsForm;
