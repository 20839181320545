import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { PiCheck, PiWarningCircle, PiXCircle } from "react-icons/pi";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

function QualificationSummary({ qualified }) {
  const [summaryData, setSummaryData] = useState(null);

  useEffect(() => {
    if (typeof qualified !== "undefined") {
      let message;
      let badgeType;
      let badgeClass;
      if (qualified === "Y") {
        message = "Congratulations! This product qualifies for preferential treatment under USMCA based on the data provided.";
        badgeType = "check";
        badgeClass = "bg-success text-dynamic-black";
      } else if (qualified === "N") {
        message = "Unfortunately, this product doesn't qualify for preferential treatment under USMCA based on the data provided.";
        badgeType = "x";
        badgeClass = "bg-danger";
      } else {
        message = "This BOM hasn't been submitted for review yet";
        badgeType = "warning";
        badgeClass = "bg-secondary";
      }
      setSummaryData({ message, badgeType, badgeClass });
    } else {
      setSummaryData(null);
    }

    return () => {
      setSummaryData(null);
    };
  }, [qualified]);

  if (!summaryData) {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <LoadingSpinner />
      </div>
    );
  }

  let badge;

  if (summaryData.badgeType === "check") {
    badge = <PiCheck />;
  } else if (summaryData.badgeType === "x") {
    badge = <PiXCircle />;
  } else {
    badge = <PiWarningCircle />;
  }

  return (
    <div>
      <p>
        {summaryData.message}
        <span style={{ transform: "translateY(-2px)" }} className={`ms-1 badge ${summaryData.badgeClass}`}>
          {badge}
        </span>
      </p>
    </div>
  );
}

QualificationSummary.defaultProps = {
  qualified: "N",
};

QualificationSummary.propTypes = {
  qualified: PropTypes.string,
};

export default QualificationSummary;
