import { Field, Form, Formik } from "formik";
import { nanoid } from "nanoid";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

import { useAnswerFinishedGoodQuestionsMutation } from "../../../features/api/boms";
import { newBomIdSelector, showFgQuestionsModal as showFgQuestionsModalSelector } from "../../../features/fileImport/fileImportSlice";
import useBom from "../../../hooks/useBom";
import useFinishedGoodQuestionsModal from "./hooks/useFinishedGoodQuestionsModal";

function QuestionAndAnswer({ question, idx }) {
  return (
    <div>
      <p className="fw-bold">{question.Question}</p>
      <div className="form-check">
        <Field type="radio" className="form-check-inline" name={`question${idx}`} value="Y" id={`question${idx}Y`} />
        <label htmlFor={`question${idx}Y`} className="form-check-label">
          Yes
        </label>
      </div>
      <div className="form-check">
        <Field type="radio" className="form-check-inline" name={`question${idx}`} value="N" id={`question${idx}N`} />
        <label htmlFor={`question${idx}N`} className="form-check-label">
          No
        </label>
      </div>
    </div>
  );
}

QuestionAndAnswer.propTypes = {
  question: PropTypes.shape({
    RuleID: PropTypes.string,
    Question: PropTypes.string,
    AnsLabel: PropTypes.string,
  }).isRequired,
  idx: PropTypes.number.isRequired,
};

function AnswerFGQuestionsModal() {
  const { handleSubmit, handleClose } = useFinishedGoodQuestionsModal();
  const newBomId = useSelector(newBomIdSelector);
  const { bom } = useBom(newBomId);
  const show = useSelector(showFgQuestionsModalSelector);
  const { isError } = useAnswerFinishedGoodQuestionsMutation()[1];

  const initialValues = useMemo(() => {
    const values = {};
    if (bom) {
      for (let i = 0; i < bom.FG_Qs.length; i += 1) {
        values[`question${i}`] = "N";
      }
    }

    return values;
  }, [bom]);

  if (!bom) {
    return null;
  }
  return (
    <Modal centered scrollable show={show}>
      <Modal.Body>
        <Formik onSubmit={handleSubmit} initialValues={initialValues}>
          {({ isSubmitting }) => (
            <Form>
              <div className="mb-5">
                <p className="alert alert-info">Please, answer the following questions about your BOM.</p>
                {bom.FG_Qs.map((question, idx) => (
                  <div key={nanoid()} className="mb-3">
                    <QuestionAndAnswer question={question} idx={idx} />
                  </div>
                ))}
              </div>
              {isError && <p className="alert alert-danger">Unable to submit answers. Please try again.</p>}
              <div className="d-flex justify-content-center gap-2">
                <button onClick={handleClose} type="button" className="btn btn-outline-primary fw-bold text-uppercase">
                  Cancel
                </button>
                <button disabled={isSubmitting} type="submit" className="btn btn-primary fw-bold text-uppercase">
                  Save & Continue
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default AnswerFGQuestionsModal;
