import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { useCreateBOMMutation } from "../../../../features/api/boms";
import { setNewBomId, setSubmittingToApi } from "../../../../features/fileImport/fileImportSlice";

export default function useCreateBOM() {
  const [createBOM, { isError: createBOMIsError, isSuccess: createBOMIsSuccess, data: createBOMData }] = useCreateBOMMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (createBOMIsError) {
      Swal.fire({
        title: "Error!",
        text: "Couldn't create your BOM. Please try again.",
        icon: "error",
      }).then(() => {
        dispatch(setSubmittingToApi(true));
        navigate("/dashboard", { replace: true });
      });
    } else if (createBOMIsSuccess && createBOMData) {
      dispatch(setNewBomId(createBOMData.BOMID));
    }

    return () => {
      setNewBomId(null);
    };
  }, [createBOMIsError, createBOMIsSuccess, createBOMData, navigate, dispatch]);

  return useMemo(() => ({ createBOM }), [createBOM]);
}
