import useBom from "../../hooks/useBom";
import { htsDisplay } from "../../utils/bom";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

export default function FinishedGoodDetailsTable({ bomId }) {
  const { bom } = useBom(bomId);

  if (!bom) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <table style={{ verticalAlign: "middle", borderSpacing: 0, borderCollapse: "separate", overflow: "hidden" }} className="table border border-light-3 rounded">
      <thead>
        <tr>
          <th className="bg-light-2 border-start rounded-top-start" scope="col">
            BOM ID
          </th>
          <th className="bg-light-2" scope="col">
            Part Number
          </th>
          <th className="bg-light-2" scope="col">
            Description
          </th>
          <th className="bg-light-2" scope="col">
            HTS
          </th>
          <th className="bg-light-2" scope="col">
            Transaction Value ({bom.FG.Currency})
          </th>
          <th className="bg-light-2" scope="col">
            Net Cost ({bom.FG.Currency})
          </th>
          <th className="bg-light-2" scope="col">
            Total Cost ({bom.FG.Currency})
          </th>
          <th className="bg-light-2 border-end rounded-top-end" scope="col">
            Origin
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{bom.BOMID}</td>
          <td>{bom.FG.Part}</td>
          <td>{bom.FG.Desc}</td>
          <td>{htsDisplay(bom.FG.Hts)}</td>
          <td>{bom.FG.Value}</td>
          <td>{bom.FG.NetCost}</td>
          <td>{bom.FG.TotalCost}</td>
          <td>{bom.FG.Origin}</td>
        </tr>
      </tbody>
    </table>
  );
}
