import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setBomId } from "../../../../features/bomWizard/bomWizardSlice";
import {
  addSuccessfulImport,
  newBomIdSelector,
  setShowComponentQuestionsModal,
  setShowFgQuestionsModal,
  submittingToApi as submittingToApiSelector,
} from "../../../../features/fileImport/fileImportSlice";
import { setShowLoadingModal } from "../../../../features/ui/miscUISlice";
import useBom from "../../../../hooks/useBom";

export default function useBOMStateActions() {
  const newBomId = useSelector(newBomIdSelector);
  const { bom } = useBom(newBomId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const submittingToApi = useSelector(submittingToApiSelector);

  useEffect(() => {
    if (bom) {
      if (bom.BOM_State === "FG_QUALIFYING") {
        dispatch(setShowFgQuestionsModal(true));
      } else if (bom.BOM_State === "COMPS_QUALIFYING") {
        dispatch(setShowComponentQuestionsModal(true));
      } else if (bom.BOM_State === "COMPS_COMPLETE") {
        dispatch(setBomId(bom.BOMID));
        dispatch(addSuccessfulImport({ id: bom.BOMID }));
        navigate("/dashboard/create-bill-of-material/submission/", { replace: true });
      }
    }
  }, [bom, dispatch, navigate]);

  useEffect(() => {
    if (submittingToApi) {
      dispatch(setShowLoadingModal(true));
    } else {
      dispatch(setShowLoadingModal(false));
    }

    return () => {
      dispatch(setShowLoadingModal(false));
    };
  }, [submittingToApi, dispatch]);
}
