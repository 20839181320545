import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useAnswerComponentQuestionsMutation } from "../../../../features/api/boms";
import { clearState, newBomIdSelector, setShowComponentQuestionsModal } from "../../../../features/fileImport/fileImportSlice";
import useBom from "../../../../hooks/useBom";

export default function useComponentQuestionsModal() {
  const newBomId = useSelector(newBomIdSelector);
  const { bom } = useBom(newBomId);
  const answerComponentQuestions = useAnswerComponentQuestionsMutation()[0];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    dispatch(setShowComponentQuestionsModal(false));
    dispatch(clearState());
    const [part1, part2] = bom.BOMID.split("#");
    navigate(`/dashboard/bom/${part1}/${part2}/`);
  }, [bom, dispatch, navigate]);

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      setSubmitting(true);
      const questionsWithAnswers = bom.Comp_Qs.map((question) => {
        const answer = values[question.RuleID.replaceAll("#", "_")];
        return { ...question, CompID: question.RuleID, Answer: answer };
      });
      answerComponentQuestions({ BOMID: bom.BOMID, As: questionsWithAnswers }).finally(() => {
        setSubmitting(false);
      });
    },
    [answerComponentQuestions, bom],
  );

  useEffect(() => {
    if (bom && bom.BOM_State === "COMPS_QUALIFYING") {
      dispatch(setShowComponentQuestionsModal(true));
    } else {
      dispatch(setShowComponentQuestionsModal(false));
    }
  }, [bom, dispatch]);

  return useMemo(() => ({ handleSubmit, handleClose }), [handleSubmit, handleClose]);
}
