import PropTypes from "prop-types";
import React from "react";

import Progress from "../../../components/Progress/Progress";

function ProgressSteps({ activeIdx }) {
  return (
    <Progress
      items={[
        {
          name: "FINISHED GOOD DETAILS",
          active: activeIdx >= 0,
        },
        {
          name: "FINISHED GOOD QUESTIONS",
          active: activeIdx >= 1,
        },
        { name: "COMPONENT DETAILS", active: activeIdx >= 2 },
        { name: "COMPONENT QUESTIONS", active: activeIdx >= 3 },
        { name: "SUBMISSION", active: activeIdx >= 4 },
      ]}
    />
  );
}

ProgressSteps.propTypes = {
  activeIdx: PropTypes.number.isRequired,
};

export default ProgressSteps;
