import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { useListBomsQuery } from "../../../../../features/api/boms";
import { selectedBomIdsSelector } from "../../../../../features/certificateOfOrigin/certificateOfOriginSlice";

export default function useQualifiedBills() {
  const selectedBomIds = useSelector(selectedBomIdsSelector);
  const [selectedBoms, setSelectedBoms] = useState([]);
  const [loading, setLoading] = useState(true);
  const { data: allBoms, isLoading } = useListBomsQuery();

  useEffect(() => {
    if (allBoms && allBoms.completed && Array.isArray(allBoms.completed) && allBoms.completed.length > 0) {
      setSelectedBoms(allBoms.completed.filter((bom) => selectedBomIds.indexOf(bom.BOMID) !== -1));
    } else {
      setSelectedBoms([]);
    }

    return () => setSelectedBoms([]);
  }, [allBoms, selectedBomIds]);

  useEffect(() => {
    if (isLoading && !selectedBoms) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    return () => setLoading(false);
  }, [isLoading, selectedBoms]);

  return useMemo(() => ({ selectedBoms, loading }), [selectedBoms, loading]);
}
