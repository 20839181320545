import Decimal from "decimal.js";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

function ExtendedCost({ idx, currency }) {
  const { values } = useFormikContext();
  const [extCost, setExtCost] = useState(0);
  useEffect(() => {
    if (values && values.components && values.components[idx] && values.components[idx].cost && values.components[idx].qty) {
      const { cost, qty } = values.components[idx];
      const costFloat = parseFloat(cost);
      const qtyFloat = parseFloat(qty);

      if (!Number.isNaN(costFloat) && !Number.isNaN(qtyFloat)) {
        const costDecimal = new Decimal(costFloat);
        setExtCost(costDecimal.mul(qtyFloat));
      } else {
        setExtCost(0);
      }
    } else {
      setExtCost(0);
    }

    return () => setExtCost(0);
  }, [values, idx]);

  return (
    <>
      <label htmlFor={`extCost${idx}`} className="form-label">
        Extended Cost ({currency})
      </label>
      <input className="form-control" type="number" value={extCost} disabled />
    </>
  );
}

ExtendedCost.defaultProps = {
  currency: "USD",
};

ExtendedCost.propTypes = {
  idx: PropTypes.number.isRequired,
  currency: PropTypes.string,
};

export default ExtendedCost;
