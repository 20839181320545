import * as Sentry from "@sentry/react";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useCreateBOMMutation } from "../../../features/api/boms";
import {
  finishedGoodSelector,
  setBomId,
  setDefaultCurrency,
  setFinishedGood,
  setFinishedGoodQuestions,
  setSkipCreation,
  skipCreationSelector,
} from "../../../features/bomWizard/bomWizardSlice";

export default function useNewBOM() {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const finishedGood = useSelector(finishedGoodSelector);
  const [createBOM, { isError, error: bomCreationError }] = useCreateBOMMutation();
  const skipCreation = useSelector(skipCreationSelector);
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async (values, { setSubmitting }) => {
      setError(null);
      try {
        const requestBody = { ...values };
        requestBody.Hts = requestBody.Hts.replaceAll(".", "");
        if (requestBody.Hts[0] === "0") {
          requestBody.Hts = requestBody.Hts.slice(1);
        }
        dispatch(setFinishedGood(requestBody));
        dispatch(setDefaultCurrency(values.Currency));
        if (!skipCreation) {
          const result = await createBOM(requestBody);
          dispatch(setBomId(result.data.BOMID));
          dispatch(setFinishedGoodQuestions(result.data));
          dispatch(setSkipCreation(true));
        }
        setSubmitting(false);
        navigate("/dashboard/finished-good-questions/");
      } catch (err) {
        if (err.response?.status !== 401) {
          Sentry.captureException(err);
          setError("We were unable to submit your information please try again.");
        }
      }
    },
    [navigate, createBOM, skipCreation, dispatch],
  );

  return useMemo(
    () => ({
      finishedGood,
      error,
      isError,
      onSubmit,
      bomCreationError,
    }),
    [finishedGood, error, isError, onSubmit, bomCreationError],
  );
}
