import dayjs from "dayjs";

import { DEFAULT_DAY_JS_DATETIME_FORMAT } from "../constants";

export function getTimestamp(bom) {
  return bom.TS || bom.BOMID.split("#")[1];
}

export function componentAlreadySubmitted(components, currentIdx) {
  return !!(components && Array.isArray(components) && components.length > 0 && components[currentIdx]);
}

/**
 *
 * @param hts {string}
 */
export function htsDisplay(hts) {
  if (!hts) {
    return "";
  }
  if (hts && hts.length !== 9 && hts.length !== 10) {
    return hts;
  }
  let newHts;
  if (hts && hts.length === 9) {
    newHts = `0${hts}`;
  } else {
    newHts = hts;
  }
  return `${newHts.slice(0, 4)}.${newHts.slice(4, 6)}.${newHts.slice(6)}`;
}

/**
 *
 * @param bomId {string}
 */
export function getBomDetailUrl(bomId) {
  const [part1, part2] = bomId.split("#");
  return `/dashboard/bom/${part1}/${part2}/`;
}

export function getSubmitTS(ts) {
  if (ts) {
    return dayjs.unix(ts).format(DEFAULT_DAY_JS_DATETIME_FORMAT);
  }

  return "-";
}

export function componentCategoryDisplay(category) {
  if (category === "Material") {
    return "Material Costs";
  }
  if (category === "OtherProduct") {
    return "Other Products";
  }
  if (category === "Period") {
    return "Period Costs";
  }
  if (category === "Other") {
    return "Other Costs";
  }
  if (category === "Accessories") {
    return "Accessories, spare parts, tools, or instructional or other information materials";
  }
  if (category === "Packaging") {
    return "Packaging materials and containers";
  }
  return "Unknown";
}

export function moneyValuePresent(val) {
  if (typeof val === "string") {
    if (val.length > 0) {
      return val.indexOf("0") !== 0;
    }
    return false;
  }
  if (typeof val === "number") {
    return val > 0;
  }
  return false;
}

export function validMoneyValue(val) {
  if (typeof val === "string") {
    if (val.match(/-/)) {
      return false;
    }
    return true;
  }
  if (typeof val === "number") {
    return val > 0;
  }
  return false;
}

export function transactionValueOrNetCostValid(transactionValue, netCost) {
  const tPresent = moneyValuePresent(transactionValue);
  const nPresent = moneyValuePresent(netCost);
  if (!tPresent && !nPresent) {
    return [false, "Please provide either the transaction value or net cost, or both of them."];
  }
  if (tPresent) {
    const tValid = validMoneyValue(transactionValue);
    if (!tValid) {
      return [false, "Transaction value must be a positive number."];
    }
  }

  if (nPresent) {
    const nValid = validMoneyValue(netCost);
    if (!nValid) {
      return [false, "Net cost must be a positive number."];
    }
  }

  return [true, null];
}

export function qualificationReasonsToMarkdownList(reason) {
  if (typeof reason === "string") {
    const parts = reason.split("|OR");
    if (parts.length > 1) {
      return parts.reduce((prev, cur) => `${prev}- ${cur}\n`, "");
    }
    return parts[0];
  }

  return "";
}
