import { Document, Image, PDFDownloadLink, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { PiArrowCircleDown, PiArrowLeft, PiCards, PiCheck, PiFunnel, PiList, PiPlus, PiX } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import BOMCard from "../../components/BOMCard/BOMCard";
import BOMTableHeader from "../../components/BOMTableHeader/BOMTableHeader";
import BOMTableRow from "../../components/BOMTableRow/BOMTableRow";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { DEFAULT_DAY_JS_DATETIME_FORMAT } from "../../constants";
import { useListBomsQuery } from "../../features/api/boms";
import { currentIdTokenSelector } from "../../features/auth/authSlice";
import { clearState } from "../../features/fileImport/fileImportSlice";
import logoImg from "../../images/logo.png";

const SHOW_QUALIFIED_FIRST = "qualified";
const SHOW_UNQUALIFIED_FIRST = "unqualified";
const SHOW_AS_CARDS = "cards";
const SHOW_AS_LIST = "list";

const TABLE_BORDER = "1px solid black";

const ImportSummaryContext = createContext({});

function ImportSummaryContextProvider({ children }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState(SHOW_QUALIFIED_FIRST);
  const [showAs, setShowAs] = useState(SHOW_AS_CARDS);
  const { data: bomList } = useListBomsQuery();
  const [importedIds, setImportedIds] = useState([]);
  const [importedBoms, setImportedBoms] = useState([]);
  const [sortedBoms, setSortedBoms] = useState([]);
  const [unparsedBoms, setUnparsedBoms] = useState([]);
  const [successfulImports, setSucccessfulImports] = useState([]);
  const [fileName, setFileName] = useState(null);

  const toggleShowAs = useCallback(() => {
    setShowAs((currentShowAs) => {
      if (currentShowAs === SHOW_AS_CARDS) {
        return SHOW_AS_LIST;
      }
      return SHOW_AS_CARDS;
    });
  }, []);

  useEffect(() => {
    if (bomList && importedIds && importedIds.length > 0) {
      setImportedBoms([...bomList.open, ...bomList.completed].filter((bomData) => importedIds.indexOf(bomData.BOMID) !== -1));
    }

    return () => {
      setImportedBoms([]);
    };
  }, [bomList, importedIds]);

  useEffect(() => {
    if (location && location.state) {
      if (location.state.successfulImports && location.state.successfulImports.length > 0) {
        setSucccessfulImports(location.state.successfulImports);
      }

      if (location.state.unparsedBoms && location.state.unparsedBoms.length > 0) {
        setUnparsedBoms(location.state.unparsedBoms);
      }

      setFileName(location.state.fileName);
    }

    return () => {
      setImportedIds([]);
    };
  }, [location]);

  useEffect(() => {
    if (successfulImports.length > 0) {
      setImportedIds(successfulImports.map((importData) => importData.id));
    }
  }, [successfulImports]);

  useEffect(() => {
    if (importedBoms && importedBoms.length > 0) {
      setSortedBoms(() => {
        const currentBomsCopy = [...importedBoms];
        if (sortBy === SHOW_QUALIFIED_FIRST) {
          return currentBomsCopy.sort((bomA, bomB) => {
            if (bomA.Qualified === "Y" && bomB.Qualified === "Y") {
              return 0;
            }
            if (bomA.Qualified === "Y" && bomB.Qualified === "N") {
              return -1;
            }
            return 1;
          });
        }
        return currentBomsCopy.sort((bomA, bomB) => {
          if (bomB.Qualified === "Y" && bomA.Qualified === "Y") {
            return 0;
          }
          if (bomB.Qualified === "Y" && bomA.Qualified === "N") {
            return -1;
          }
          return 1;
        });
      });
    }
  }, [importedBoms, sortBy]);

  useEffect(() => {
    if (sortedBoms.length > 0) {
      setLoading(false);
    }

    return () => {
      setLoading(true);
    };
  }, [sortedBoms]);

  useEffect(() => {
    dispatch(clearState(true));
  }, [dispatch]);

  const value = useMemo(
    () => ({ sortBy, setSortBy, sortedBoms, showAs, toggleShowAs, successfulImports, unparsedBoms, fileName, loading }),
    [sortBy, setSortBy, sortedBoms, showAs, toggleShowAs, successfulImports, unparsedBoms, fileName, loading],
  );
  return <ImportSummaryContext.Provider value={value}>{children}</ImportSummaryContext.Provider>;
}

function useImportSummaryContext() {
  return useContext(ImportSummaryContext);
}

function QualificationTD({ qualified }) {
  if (qualified) {
    return (
      <span className="text-success fw-bold">
        <PiCheck /> Qualified
      </span>
    );
  }
  return (
    <span className="text-danger fw-bold">
      <PiX /> Unqualified
    </span>
  );
}

QualificationTD.propTypes = {
  qualified: PropTypes.bool.isRequired,
};

function Rows() {
  const { sortedBoms } = useImportSummaryContext();

  return sortedBoms.map((bom) => <BOMTableRow bomId={bom.BOMID} />);
}

function ImportCountDisplay() {
  const [alertType, setAlertType] = useState("success");
  const { sortedBoms, unparsedBoms, loading } = useImportSummaryContext();

  useEffect(() => {
    if (Number(unparsedBoms?.length || "0") === sortedBoms.length) {
      setAlertType("success");
    } else {
      setAlertType("warning");
    }

    return () => {
      setAlertType("success");
    };
  }, [unparsedBoms, sortedBoms]);

  if (loading) {
    return <p className="alert alert-info text-center">Please wait...</p>;
  }

  return (
    <p className={`alert alert-${alertType} text-center`}>
      Imported {sortedBoms.length !== Number(unparsedBoms?.length || 0) && "only "}
      <b>{sortedBoms.length}</b> of the <b>{unparsedBoms?.length || 0}</b> submitted BOMs.
    </p>
  );
}

function Toolbar() {
  const { sortBy, setSortBy, showAs, toggleShowAs } = useImportSummaryContext();
  return (
    <div className="btn-group" role="group" aria-label="Toolbar">
      <button onClick={toggleShowAs} type="button" className="btn btn-outline-primary">
        {showAs === SHOW_AS_CARDS ? <PiList /> : <PiCards />}
      </button>
      <Dropdown as={ButtonGroup}>
        <Dropdown.Toggle as="button" className="btn btn-outline-primary">
          <span style={{ display: "inline-block", transform: "translateY(-1px)", marginRight: "4px" }}>
            <PiFunnel />
          </span>
          Sort
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setSortBy(SHOW_QUALIFIED_FIRST)} as="button">
            <span className="d-flex gap-1 align-items-center">{sortBy === SHOW_QUALIFIED_FIRST && <PiCheck />}Qualified first</span>
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setSortBy(SHOW_UNQUALIFIED_FIRST)} as="button">
            <span className="d-flex gap-1 align-items-center">{sortBy === SHOW_UNQUALIFIED_FIRST && <PiCheck />}Unqualified first</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

function BOMTable() {
  return (
    <table style={{ verticalAlign: "middle" }} className="table mb-5">
      <BOMTableHeader />
      <tbody>
        <Rows />
      </tbody>
    </table>
  );
}

function BOMCards() {
  const { sortedBoms } = useImportSummaryContext();
  return (
    <div className="w-100">
      {sortedBoms.map((bom) => (
        <div className="mb-3" key={bom.BOMID}>
          <BOMCard bomId={bom.BOMID} />
        </div>
      ))}
    </div>
  );
}

function Body() {
  const { showAs, loading } = useImportSummaryContext();

  if (loading) {
    return (
      <div className="my-5 d-flex justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (showAs === SHOW_AS_CARDS) {
    return <BOMCards />;
  }
  return <BOMTable />;
}

function PDFPage({ children }) {
  const styles = StyleSheet.create({ page: { padding: "10vh 10vw", fontFamily: "Courier", fontSize: "12pt" } });
  return (
    <Page size="LETTER" style={styles.page}>
      {children}
    </Page>
  );
}

function PDFImportStats({ fileName }) {
  const styles = StyleSheet.create({ boldText: { fontFamily: "Courier-Bold" }, line: { marginBottom: "20pt" } });
  return (
    <View>
      <View style={styles.line}>
        <Text>
          <Text style={styles.boldText}>File Name:</Text> {fileName || "unknown"}
        </Text>
      </View>
      <View>
        <Text>
          <Text style={styles.boldText}>Date:</Text> {dayjs().format(DEFAULT_DAY_JS_DATETIME_FORMAT)}
        </Text>
      </View>
    </View>
  );
}

function PDFHeader({ children }) {
  const styles = StyleSheet.create({
    header: { display: "flex", width: "100%", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "50pt" },
  });

  return <View style={styles.header}>{children}</View>;
}

function PDFLogoImg() {
  const styles = StyleSheet.create({ logoImg: { width: "25vw" } });

  return <Image style={styles.logoImg} src={logoImg} />;
}

function PDFImportCountNotice({ unparsedCount, sortedCount }) {
  const styles = StyleSheet.create({ notice: { textAlign: "center", marginBottom: "50pt" }, boldText: { fontFamily: "Courier-Bold", fontWeight: "bold" } });
  return (
    <View style={styles.notice}>
      <Text>
        You imported {sortedCount !== unparsedCount && "only "}
        <Text style={styles.boldText}>{sortedCount}</Text> out of <Text style={styles.boldText}>{unparsedCount}</Text> submitted BOMs.
      </Text>
    </View>
  );
}

function PDFTable({ children }) {
  const styles = StyleSheet.create({
    table: { width: "100%" },
  });
  return <View style={styles.table}>{children}</View>;
}

function PDFTableData({ isHeader, col, children, lastRow }) {
  const [styleObj, setStyleObj] = useState({});
  const styles = StyleSheet.create({
    td: styleObj,
  });

  useEffect(() => {
    const style = {
      padding: "5pt",
      flexGrow: 1,
      textAlign: "center",
      width: "100%",
      height: "100%",
      borderTop: TABLE_BORDER,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };
    if (isHeader) {
      style.fontFamily = "Courier-Bold";
    }

    if (col === 0) {
      style.borderLeft = TABLE_BORDER;
      style.borderRight = TABLE_BORDER;
    } else {
      style.borderRight = TABLE_BORDER;
    }

    if (lastRow) {
      style.borderBottom = TABLE_BORDER;
    }
    setStyleObj(style);
  }, [isHeader, col, lastRow]);

  return (
    <View style={styles.td}>
      <Text>{children}</Text>
    </View>
  );
}

function PDFTableHeader() {
  const styles = StyleSheet.create({
    header: { flexDirection: "row" },
  });

  return (
    <View style={styles.header}>
      {["ID", "Part Number", "Components", "Status"].map((txt, idx) => (
        <PDFTableData key={txt} isHeader col={idx}>
          {txt}
        </PDFTableData>
      ))}
    </View>
  );
}

function PDFTableBodyRow({ bom, lastRow, idToken }) {
  const [bomDetail, setBomDetail] = useState(null);
  const [statusText, setStatusText] = useState(null);

  useEffect(() => {
    if (bom.Qualified === "Y") {
      setStatusText("Qualified");
    } else if (bom.Qualified === "N") {
      setStatusText("Unqualified");
    } else {
      setStatusText("Saved");
    }
  }, [bom]);

  useEffect(() => {
    let ignore = false;
    const [part1, part2] = bom.BOMID.split("#");

    fetch(`${process.env.REACT_APP_API_URL}/bom?part1=${part1}&part2=${part2}`, { method: "GET", headers: { authorization: idToken } })
      .then((res) => res.json())
      .then((data) => {
        if (!ignore) {
          setBomDetail(data);
        }
      });

    return () => {
      ignore = true;
    };
  }, [bom, idToken]);

  return [bom.BOMID, bom.Part, bomDetail?.Components?.length || 0, statusText].map((txt, idx) => (
    <PDFTableData key={txt} col={idx} lastRow={lastRow}>
      {txt}
    </PDFTableData>
  ));
}

function PDFTableBody({ sortedBoms, idToken }) {
  const styles = StyleSheet.create({ tbody: { flexDirection: "row" } });

  return (
    <View>
      {sortedBoms.map((bom, idx) => (
        <View style={styles.tbody} key={bom.BOMID}>
          <PDFTableBodyRow idToken={idToken} bom={bom} lastRow={idx === sortedBoms.length - 1} />
        </View>
      ))}
    </View>
  );
}

function PDFTitle() {
  const styles = StyleSheet.create({ section: { marginBottom: "40pt" }, txt: { fontSize: "24pt", textAlign: "center" } });
  return (
    <View style={styles.section}>
      <Text style={styles.txt}>Summary</Text>
    </View>
  );
}

function PDFDocument({ unparsedBoms, sortedBoms, idToken, fileName }) {
  return (
    <Document>
      <PDFPage>
        <PDFHeader>
          <PDFLogoImg />
          <PDFImportStats fileName={fileName} />
        </PDFHeader>
        <PDFTitle />
        <View>
          <PDFImportCountNotice sortedCount={sortedBoms?.length || 0} unparsedCount={unparsedBoms?.length || 0} />
          <PDFTable>
            <PDFTableHeader />
            <PDFTableBody idToken={idToken} sortedBoms={sortedBoms} />
          </PDFTable>
        </View>
      </PDFPage>
    </Document>
  );
}

function PDFReportBtn() {
  const { unparsedBoms, sortedBoms, fileName } = useImportSummaryContext();
  const idToken = useSelector(currentIdTokenSelector);
  return (
    <PDFDownloadLink
      fileName="import-summary.pdf"
      document={<PDFDocument unparsedBoms={unparsedBoms} sortedBoms={sortedBoms} fileName={fileName} idToken={idToken} />}
      className="btn btn-outline-primary"
    >
      {({ loading }) =>
        loading ? (
          <LoadingSpinner />
        ) : (
          <span className="d-flex gap-1 align-items-center">
            <span className="d-inline-block">
              <PiArrowCircleDown />
            </span>
            PDF Report
          </span>
        )
      }
    </PDFDownloadLink>
  );
}

export default function ImportSummary() {
  const navigate = useNavigate();

  return (
    <ImportSummaryContextProvider>
      <div className="container pt-5">
        <div className="row mb-5">
          <div className="col-12">
            <h1 className="text-center">Import Summary</h1>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-lg-3" />
          <div className="col-lg-6">
            <ImportCountDisplay />
          </div>
          <div className="col-lg-3" />
        </div>
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-end mb-5">
              <Toolbar />
            </div>

            <div className="d-flex justify-content-center mb-5">
              <Body />
            </div>

            <div className="d-flex gap-2 justify-content-center align-items-center">
              <button type="button" onClick={() => navigate("/dashboard")} className="btn btn-primary">
                <span className="d-inline-block me-2">
                  <PiArrowLeft />
                </span>
                Qualification History
              </button>
              <PDFReportBtn />
              <button type="button" onClick={() => navigate("/dashboard/import-bom-csv/")} className="btn btn-outline-primary">
                <span className="d-inline-block me-1">
                  <PiPlus />
                </span>
                Import another file
              </button>
            </div>
          </div>
        </div>
      </div>
    </ImportSummaryContextProvider>
  );
}
