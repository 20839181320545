import { useFormikContext } from "formik";
import React from "react";

import FormikFieldGroup from "../../../../components/FormikFieldGroup/FormikFieldGroup";
import useInsertHtsDots from "../../../../hooks/useInsertHtsDots";

function HTSField() {
  const { values } = useFormikContext();
  useInsertHtsDots("hts", values?.hts);

  return <FormikFieldGroup name="hts" type="text" placeholder="Ex. 0000.00.0000" label="HTS*" required />;
}

export default HTSField;
