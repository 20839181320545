import React from "react";

import ProgressBar from "../components/ProgressBar/ProgressBar";
import { FINISHED_GOOD_DETAILS_STEP } from "../constants";
import FinishedGoodForm from "./FinishedGoodForm/FinishedGoodForm";

export default function FinishedGoodDetails() {
  return (
    <div className="container pt-5">
      <div className="row">
        <div className="col-lg-2" />
        <div className="col-lg-8">
          <div className="mb-5 d-flex w-100">
            <ProgressBar step={FINISHED_GOOD_DETAILS_STEP} />
          </div>
          <div className="mb-5">
            <h1 className="mb-3">Create a Bill of Material</h1>
            <p>Start by providing details about the finished good you would like to qualify for USMCA.</p>
          </div>
          <FinishedGoodForm />
        </div>
        <div className="col-lg-2" />
      </div>
    </div>
  );
}
