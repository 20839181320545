import { useCallback, useMemo } from "react";

import useFinishBom from "../../../BOMTableRow/hooks/useFinishBom";

export default function useContinueAction() {
  const { handleFinish } = useFinishBom();
  const handleContinue = useCallback(
    (bom) => {
      handleFinish(bom);
    },
    [handleFinish],
  );

  return useMemo(() => ({ handleContinue }), [handleContinue]);
}
