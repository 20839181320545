import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { useCloseBomMutation } from "../../../features/api/boms";
import { bomIdSelector } from "../../../features/bomWizard/bomWizardSlice";
import {
  clearState,
  currentBOMImportIndexSelector,
  incrementBOMImportIndex,
  successfulImportsSelector,
  unparsedBOMsSelector,
} from "../../../features/fileImport/fileImportSlice";
import useBom from "../../../hooks/useBom";
import useNavigateToImportSummary from "../../../hooks/useNavigateToImportSummary";

function useContinueImport() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return useCallback(() => {
    Swal.fire("Import Successful!", "You will now be redirected to import the next enqueued BOM.").then(() => {
      dispatch(incrementBOMImportIndex());
      navigate("/dashboard/import-bom-csv/");
    });
  }, [dispatch, navigate]);
}

function useHandleClosedBom(bom) {
  const currentImportIndex = useSelector(currentBOMImportIndexSelector);
  const dispatch = useDispatch();
  const successfulImports = useSelector(successfulImportsSelector);
  const continueImport = useContinueImport();
  const [closedBomHandlerAction, setClosedBomHandlerAction] = useState(null);
  const navigateToImportSummary = useNavigateToImportSummary();
  const unparsedBoms = useSelector(unparsedBOMsSelector);
  const navigate = useNavigate();

  useEffect(() => {
    const hasUnparsed = unparsedBoms && Array.isArray(unparsedBoms) && unparsedBoms.length > 0;
    const importsRemaining = unparsedBoms.length - (currentImportIndex + 1);
    if (bom && bom.BOM_State === "CLOSED") {
      if (hasUnparsed && importsRemaining > 0) {
        setClosedBomHandlerAction("continue");
      } else if (unparsedBoms && unparsedBoms.length > 1 && importsRemaining === 0) {
        setClosedBomHandlerAction("summary");
      } else {
        setClosedBomHandlerAction("detail");
      }
    }

    return () => {
      setClosedBomHandlerAction(null);
    };
  }, [bom, navigate, continueImport, unparsedBoms, currentImportIndex, dispatch]);

  useEffect(() => {
    if (bom) {
      if (closedBomHandlerAction === "continue") {
        continueImport();
      } else if (closedBomHandlerAction === "detail") {
        dispatch(clearState(true));
        const [username, timestamp] = bom.BOMID.split("#");
        navigate(`/dashboard/bom/${username}/${timestamp}/`);
      }
    }
  }, [closedBomHandlerAction, bom, dispatch, navigate, continueImport]);

  useEffect(() => {
    if (bom) {
      if (closedBomHandlerAction === "summary") {
        navigateToImportSummary();
      }
    }
  }, [closedBomHandlerAction, navigateToImportSummary, navigate, dispatch, successfulImports, unparsedBoms, bom]);
}

function useSubmission() {
  const bomId = useSelector(bomIdSelector);
  const { bom } = useBom(bomId);
  const successfulImports = useSelector(successfulImportsSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigateToImportSummary = useNavigateToImportSummary();
  useHandleClosedBom(bom);
  const [closeBom, { isError }] = useCloseBomMutation();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(() => {
    setSubmitting(true);
    closeBom({ BOMID: bomId });
  }, [bomId, closeBom]);

  const handleCancel = useCallback(() => {
    if (successfulImports && successfulImports.length > 0) {
      navigateToImportSummary();
    } else {
      dispatch(clearState(true));
      navigate("/dashboard", { replace: true });
    }
  }, [dispatch, navigateToImportSummary, navigate, successfulImports]);

  useEffect(() => {
    if (isError) {
      Swal.fire({
        title: "Error!",
        text: "Failed to submit your BOM. Please try again.",
        icon: "error",
      }).then(() => {
        setSubmitting(false);
      });
    }
  }, [isError]);

  return useMemo(() => ({ handleSubmit, submitting, handleCancel }), [handleSubmit, submitting, handleCancel]);
}

export default useSubmission;
