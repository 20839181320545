import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useGetBomQuery } from "../features/api/boms";
import { addErrors } from "../features/app/appStateSlice";
import { setComponents, setDefaultCurrency, setFinishedGood, setIsCloning as setIsCloningGlobal } from "../features/bomWizard/bomWizardSlice";

export default function useCloneBom() {
  const [isCloning, setIsCloning] = useState(false);
  const [fetchBomDetail, setFetchBomDetail] = useState(false);
  const [bomUsername, setBomUsername] = useState(null);
  const [bomTimeStamp, setBomTimeStamp] = useState(null);
  const { data: bomDetail, isError, error } = useGetBomQuery({ username: bomUsername, bomId: bomTimeStamp }, { skip: !fetchBomDetail });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClone = useCallback((bom) => {
    setIsCloning(true);
    const [username, timestamp] = bom.BOMID.split("#");
    setBomUsername(username);
    setBomTimeStamp(timestamp);
    setIsCloning(false);
  }, []);

  useEffect(() => {
    if (bomUsername && bomTimeStamp) {
      setFetchBomDetail(true);
    }
  }, [bomUsername, bomTimeStamp, isCloning]);

  useEffect(() => {
    if (isError) {
      dispatch(addErrors([`Failed to retrieve BOM. ${JSON.stringify(error.data || "Unknown error")}.`]));
    }
  }, [isError, error, dispatch, navigate]);

  useEffect(() => {
    if (bomDetail) {
      dispatch(setIsCloningGlobal(true));
      dispatch(setFinishedGood(bomDetail.FG));
      dispatch(setComponents(bomDetail.Components));
      dispatch(setDefaultCurrency(bomDetail.FG.Currency));
      navigate("/dashboard/create-bill-of-material/fg-details/");
    }
  }, [bomDetail, dispatch, navigate]);

  return { handleClone, isCloning };
}
