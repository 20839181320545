import { number, object, string } from "yup";

export const bomSchema = object().shape({
  Part: string().required("Part Number is required."),
  Desc: string().required("Description is required."),
  Producer: string().required("Producer is required."),
  Hts: string()
    .min(12, "HTS must contain at least 10 digits.")
    .max(12, "HTS must contain no more than 10 digits.")
    .test("USHTSNumbersOnly", "USHTS must contain numbers only.", (value) => !/[a-zA-Z]/.test(value))
    .required("Hts is required."),
  Value: number().positive().required("Value is required"),
  Origin: string().min(2, "Origin must be a 2-letter country code.").max(2, "Origin must be a 2-letter country code.").required("Origin is required."),
  Currency: string()
    .matches(/USD|MXP|CAD/)
    .required("Please specify a currency."),
});
