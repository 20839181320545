import { Field, Form, Formik } from "formik";
import { nanoid } from "nanoid";
import React, { useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

import { newBomIdSelector, showComponentQuestionsModal } from "../../../features/fileImport/fileImportSlice";
import useBom from "../../../hooks/useBom";
import componentQuestionPropType from "../../../propTypes/componentQuestionPropType";
import useComponentQuestionsModal from "./hooks/useComponentQuestionsModal";

function QuestionAndAnswers({ question }) {
  return (
    <div>
      <h4>Component {question.RuleID.split("#")[0]}</h4>
      <p className="fw-bold">{question.Question}</p>
      <div className="form-check">
        <Field className="form-check-input" id={`${question.RuleID}Y`} value="Y" type="radio" name={question.RuleID.replaceAll("#", "_")} />
        <label htmlFor={`${question.RuleID}Y`} className="form-label">
          Yes
        </label>
      </div>
      <div className="form-check">
        <Field className="form-check-input" id={`${question.RuleID}N`} value="N" type="radio" name={question.RuleID.replaceAll("#", "_")} />
        <label htmlFor={`${question.RuleID}N`} className="form-label">
          No
        </label>
      </div>
    </div>
  );
}

QuestionAndAnswers.propTypes = {
  question: componentQuestionPropType.isRequired,
};

function AnswerComponentQuestionsModal() {
  const { handleSubmit, handleClose } = useComponentQuestionsModal();
  const newBomId = useSelector(newBomIdSelector);
  const { bom } = useBom(newBomId);
  const show = useSelector(showComponentQuestionsModal);
  const initialValues = useMemo(() => {
    const values = {};
    if (bom) {
      bom.Comp_Qs.forEach((question) => {
        values[question.RuleID.replaceAll("#", "_")] = "N";
      });
    }
    return values;
  }, [bom]);

  if (!bom) {
    return null;
  }

  return (
    <Modal show={show}>
      <Modal.Body>
        <Formik onSubmit={handleSubmit} initialValues={initialValues}>
          {({ isSubmitting }) => (
            <Form>
              <p>Please answer the following questions about your components.</p>
              {bom.Comp_Qs.map((question) => (
                <div key={nanoid()} className="mb-3">
                  <QuestionAndAnswers question={question} />
                </div>
              ))}
              <div className="d-flex justify-content-center gap-2">
                <button onClick={handleClose} type="button" className="btn btn-outline-primary fw-bold text-uppercase">
                  Cancel & Go Back
                </button>
                <button disabled={isSubmitting} type="submit" className="btn btn-primary fw-bold text-uppercase">
                  Save & Continue
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default AnswerComponentQuestionsModal;
