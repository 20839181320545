import { useEffect, useMemo, useState } from "react";

import { useListBomsQuery } from "../features/api/boms";

export default function useTotalBomCount() {
  const { data: bomList } = useListBomsQuery();
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (bomList) {
      setTotal(bomList.completed.length);
    }
  }, [bomList]);

  return useMemo(() => ({ total }), [total]);
}
