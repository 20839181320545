import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import FinishedGoodDetailsCard from "../../../components/FinishedGoodDetailsCard/FinishedGoodDetailsCard";
import FixedScrollToBottomButton from "../../../components/FixedScrollToBottomButton/FixedScrollToBottomButton";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { bomIdSelector } from "../../../features/bomWizard/bomWizardSlice";
import ProgressBar from "../components/ProgressBar/ProgressBar";
import { COMPONENT_DETAILS_STEP } from "../constants";
import ComponentsForm from "./ComponentsForm";
import useComponentDetails from "./useComponentDetails";

function ComponentDetails() {
  const bomId = useSelector(bomIdSelector);
  const { loading } = useComponentDetails();

  let body;

  if (loading) {
    body = (
      <div className="d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  } else if (!loading && !bomId) {
    return <Navigate to="/dashboard" replace />;
  } else {
    body = (
      <div>
        <div className="mb-5">
          <FinishedGoodDetailsCard bomId={bomId} />
        </div>
        <ComponentsForm />
      </div>
    );
  }

  return (
    <div className="container pt-5">
      <div className="row">
        <div className="col-lg-2" />
        <div className="col-lg-8">
          <div className="mb-5 d-flex w-100">
            <ProgressBar step={COMPONENT_DETAILS_STEP} />
          </div>
          <div className="mb-5">
            <h1 className="mb-3">Add Component Details</h1>
            <p>Enter the individual components your finished good is made up of.</p>
          </div>
          {body}
        </div>
        <div className="col-lg-2" />
      </div>
      <FixedScrollToBottomButton />
    </div>
  );
}

export default ComponentDetails;
