import bootstrapIcons from "bootstrap-icons/bootstrap-icons.svg";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { DEFAULT_DAY_JS_DATETIME_FORMAT } from "../../constants";
import useCloneBom from "../../hooks/useCloneBom";
import bomFromApiPropType from "../../propTypes/bomSummaryPropType";
import { getTimestamp } from "../../utils/bom";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import PrintPDFButton from "../PrintPDFButton/PrintPDFButton";
import ViewPDFButton from "../ViewPDFButton/ViewPDFButton";
import useBomDetail from "./hooks/useBomDetail";
import useFinishBom from "./hooks/useFinishBom";

function BOMOptions({ bom }) {
  const { handleClone, isCloning } = useCloneBom();
  const { handleFinish, isLoading: finishIsLoading } = useFinishBom();

  if (bom && bom.BOM_State === "CLOSED") {
    return (
      <div className="btn-group" role="group">
        <button onClick={() => handleClone(bom)} type="button" className="btn btn-primary icon-btn" disabled={isCloning}>
          <svg>
            <use xlinkHref={`${bootstrapIcons}#clipboard`} />
          </svg>
          Clone
        </button>
        <ViewPDFButton bomId={bom.BOMID} />
        <PrintPDFButton bomId={bom.BOMID} />
      </div>
    );
  }

  if (bom && bom.BOM_State !== "CLOSED") {
    return (
      <button onClick={() => handleFinish(bom)} type="button" className="btn btn-primary icon-btn" disabled={finishIsLoading}>
        <svg>
          <use xlinkHref={`${bootstrapIcons}#pen`} />
        </svg>
        Finish BOM
      </button>
    );
  }

  return <LoadingSpinner />;
}

BOMOptions.propTypes = {
  bom: bomFromApiPropType.isRequired,
};
function BOMTableRow({ bomId }) {
  const [bomDetailUrl, setBomDetailUrl] = useState("");
  const { bomDetail } = useBomDetail(bomId);

  let statusIconName = "x-lg";

  if (bomDetail && bomDetail.Qualified === "Y") {
    statusIconName = "check-lg";
  }

  useEffect(() => {
    if (bomId) {
      const [username, timeStamp] = bomId.split("#");
      setBomDetailUrl(`/dashboard/bom/${username}/${timeStamp}/`);
    }

    return () => {
      setBomDetailUrl("");
    };
  }, [bomId]);

  return (
    <tr>
      <th scope="row">
        <Link to={bomDetailUrl}>{bomId}</Link>
      </th>
      <td>{bomDetail ? dayjs.unix(getTimestamp(bomDetail)).format(DEFAULT_DAY_JS_DATETIME_FORMAT) : ""}</td>
      <td>{bomDetail?.FG?.Hts || ""}</td>
      <td>{bomDetail?.FG?.Part || ""}</td>
      <td>{bomDetail?.FG?.Desc || ""}</td>
      <td>{bomDetail?.FG?.Value || ""}</td>
      <td>{bomDetail?.FG?.Origin || ""}</td>
      <td>
        <svg className="bi icon ms-4">
          <use xlinkHref={`${bootstrapIcons}#${statusIconName}`} />
        </svg>
      </td>
      <td>
        <BOMOptions bom={bomDetail} />
      </td>
    </tr>
  );
}

BOMTableRow.propTypes = {
  bomId: PropTypes.string.isRequired,
};
export default BOMTableRow;
