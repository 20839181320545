import { Field, useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";

import FormError from "./FormError";

function FormikSelectField({ name, label, options }) {
  const { errors, touched } = useFormikContext();
  return (
    <>
      <label htmlFor={`select${name}`} className="form-label">
        {label}
      </label>
      <Field as="select" name={name} className={`form-select ${touched && touched[name] && errors && errors[name] ? "is-invalid" : ""}`}>
        {options.map((option) => (
          <option key={`select${name}option${option.value}`} value={option.value}>
            {option.name}
          </option>
        ))}
      </Field>
      {errors && errors[name] && <FormError error={errors[name]} />}
    </>
  );
}

FormikSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, value: PropTypes.string })).isRequired,
};

export default FormikSelectField;
