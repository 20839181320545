import React from "react";

import bomDetail from "../../../../propTypes/bomDetail";
import LoadingSpinner from "../../../LoadingSpinner/LoadingSpinner";
import StatusPill from "../../../StatusPill/StatusPill";
import ActionButton from "./ActionButton";
import SettingsButton from "./SettingsButton";
import { CONTINUE_ACTION_TYPE, REAPPLY_ACTION_TYPE } from "./constants";
import useHeaderAction from "./useHeaderAction";
import useStatusPill from "./useStatusPill";

function Header({ bom }) {
  const { pillType, pillName, badgeText } = useStatusPill(bom);
  const { actionType } = useHeaderAction(bom);

  let action;

  if (bom && bom.BOM_State === "CLOSED" && bom.Qualified === "Y") {
    action = <SettingsButton bomId={bom.BOMID} />;
  } else if (bom && bom.BOM_State === "CLOSED" && bom.Qualified === "N") {
    action = (
      <div className="d-flex gap-1 align-items-center">
        <ActionButton actionType={REAPPLY_ACTION_TYPE} name="REAPPLY" bom={bom} />
        <SettingsButton bomId={bom.BOMID} />
      </div>
    );
  } else if (bom && bom.BOM_State !== "CLOSED") {
    action = (
      <div className="d-flex gap-1 align-items-center">
        <ActionButton actionType={CONTINUE_ACTION_TYPE} name="CONTINUE" bom={bom} />
        <SettingsButton bomId={bom.BOMID} />
      </div>
    );
  } else {
    action = <LoadingSpinner />;
  }

  return (
    <div className="d-flex justify-content-between align-items-center mb-3">
      {actionType !== null ? (
        <div>
          <StatusPill name={pillName} type={pillType} badgeText={badgeText} bom={bom} />
        </div>
      ) : (
        <LoadingSpinner />
      )}
      {action}
    </div>
  );
}

Header.defaultProps = {
  bom: null,
};

Header.propTypes = {
  bom: bomDetail,
};

export default Header;
