import PropTypes from "prop-types";
import React from "react";

import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import useBom from "../../../../hooks/useBom";
import certificatePropType from "../../../../propTypes/certificatePropType";

function QualifiedGood({ bomId }) {
  const { bom, loading } = useBom(bomId);

  if (loading || !bomId) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div style={{ height: "150px" }} className="border border-light-3 rounded p-3 d-flex flex-column justify-content-between">
      <div>
        <h4>{bom?.BOMID}</h4>
        <p>{bom?.FG.Desc}</p>
      </div>
      <div className="d-flex justify-content-between">
        <p className="m-0 text-muted fs-7">COO | {bom?.FG.Origin}</p>
        <p className="m-0 text-muted fs-7">HTS | {bom?.FG.Hts}</p>
        <p className="m-0 text-muted fs-7">Qualification Preference | {bom?.QualPref}</p>
      </div>
    </div>
  );
}

QualifiedGood.defaultProps = {
  bomId: null,
};

QualifiedGood.propTypes = {
  bomId: PropTypes.string,
};

function QualifiedGoods({ certificate }) {
  return (
    <div>
      <h2 className="mb-4">Qualified Goods</h2>
      <div className="bg-light-2 p-3 rounded">
        {certificate.qualified_goods.map((fg) => (
          <div key={fg.id} className="mb-3">
            <QualifiedGood bomId={fg.id} />
          </div>
        ))}
      </div>
    </div>
  );
}

QualifiedGoods.propTypes = {
  certificate: certificatePropType.isRequired,
};

export default QualifiedGoods;
