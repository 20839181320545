import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { clearState, newBomIdSelector, submittingToApi, successfulImportsSelector } from "../../../features/fileImport/fileImportSlice";
import useNavigateToImportSummary from "../../../hooks/useNavigateToImportSummary";
import ProgressBar from "../components/ProgressBar/ProgressBar";
import { MATCH_FILE_FIELDS_STEP } from "../constants";
import AnswerComponentQuestionsModal from "./AnswerComponentQuestionsModal";
import AnswerFGQuestionsModal from "./AnswerFGQuestionsModal";
import BOMDetailsMatcher from "./BOMDetailsMatcher";
import ComponentDetailsMatcher from "./ComponentDetailsMatcher/ComponentDetailsMatcher";
import useBOMStateActions from "./hooks/useBOMStateActions";
import useContinue from "./hooks/useContinue";
import useCreateComponents from "./hooks/useCreateComponents";
import useFileDataRequired from "./hooks/useFileDataRequired";

function Matchers() {
  return (
    <div className="row">
      <div className="col">
        <div className="mb-5 w-100">
          <BOMDetailsMatcher />
        </div>
        <div>
          <ComponentDetailsMatcher />
        </div>
      </div>
    </div>
  );
}

function ActionButtons() {
  const newBomId = useSelector(newBomIdSelector);
  const { handleContinue } = useContinue();
  const inProgress = useSelector(submittingToApi);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const successfulImports = useSelector(successfulImportsSelector);
  const navigateToImportSummary = useNavigateToImportSummary();

  const handleCancel = useCallback(() => {
    let redirectUrl;
    if (newBomId) {
      const [part1, part2] = newBomId.split("#");
      redirectUrl = `/dashboard/bom/${part1}/${part2}/`;
    } else {
      redirectUrl = "/dashboard/create-bill-of-material/";
    }
    if (successfulImports && successfulImports.length > 0) {
      navigateToImportSummary();
    } else {
      dispatch(clearState(true));
      navigate(redirectUrl, { replace: true });
    }
  }, [newBomId, navigateToImportSummary, navigate, dispatch, successfulImports]);

  return (
    <div className="d-flex gap-2 justify-content-center">
      <button onClick={handleCancel} type="button" className="btn btn-outline-primary fw-bold text-uppercase">
        Cancel & Go Back
      </button>
      <button disabled={inProgress} onClick={handleContinue} type="button" className="btn btn-primary text-uppercase fw-bold">
        {inProgress ? "Please wait..." : "Continue"}
      </button>
    </div>
  );
}
function MatchFileFields() {
  useFileDataRequired();
  useBOMStateActions();
  useCreateComponents();
  return (
    <>
      <div className="container pt-5">
        <div className="row">
          <div className="col-lg-2" />
          <div className="col-lg-8">
            <div className="mb-5">
              <ProgressBar step={MATCH_FILE_FIELDS_STEP} />
            </div>
          </div>
          <div className="col-lg-2" />
        </div>
        <div className="mb-5">
          <Matchers />
        </div>
        <ActionButtons />
      </div>
      <AnswerFGQuestionsModal />
      <AnswerComponentQuestionsModal />
    </>
  );
}

export default MatchFileFields;
