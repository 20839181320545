/* eslint jsx-a11y/label-has-associated-control: 0 */
import { Form, Formik } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import { number, object, string } from "yup";

import FormikFieldGroup from "../../../../components/FormikFieldGroup/FormikFieldGroup";
import FormikSelectField from "../../../../components/FormikSelectField";
import HTSField from "./HTSField";
import useFinishedGoodForm from "./useFinishedGoodForm";

const schema = object().shape({
  currency: string()
    .matches(/USD|MXP|CAD/)
    .required("Please specify a currency."),
  partNumber: string().required("Part Number is required."),
  description: string().required("Description is required."),
  hts: string()
    .min(12, "HTS must contain at least 10 digits.")
    .max(12, "HTS must contain no more than 10 digits.")
    .test("USHTSNumbersOnly", "USHTS must contain numbers only.", (value) => !/[a-zA-Z]/.test(value))
    .required("Hts is required."),
  value: number().positive("Enter a positive number or leave blank."),
  coo: string().min(2, "Origin must be a 2-letter country code."),
  netCost: number().positive("Enter a positive number or leave blank."),
  totalCost: number().positive("Enter a positive number or leave blank."),
});

function FinishedGoodForm() {
  const { initialValues, handleSubmit } = useFinishedGoodForm();

  return (
    <Formik enableReinitialize validationSchema={schema} onSubmit={handleSubmit} initialValues={initialValues}>
      {({ isSubmitting }) => (
        <Form>
          <div className="mb-5">
            <h2 className="mb-4">Finished Good Product Information</h2>
            <div className="mb-3">
              <FormikSelectField name="currency" options={["USD", "MXD", "CAD"].map((currency) => ({ name: currency, value: currency }))} label="Currency of Import*" />
            </div>

            <div className="mb-3">
              <FormikFieldGroup name="partNumber" type="text" label="Part Number*" required placeholder="Ex. F123456" />
            </div>

            <div>
              <FormikFieldGroup name="description" label="Description*" type="text" required placeholder="Ex. Woven Fabric" />
            </div>
          </div>

          <div className="mb-5">
            <h2 className="mb-4">Finished Good Trade Information</h2>

            <div className="mb-3">
              <HTSField />
            </div>

            <div className="card mb-3">
              <div className="card-header">
                <h4 className="m-0">Costs</h4>
              </div>
              <div className="card-body">
                <p className="mb-3">
                  <b>Enter either the transaction value, the net cost, or both of them.</b>
                </p>
                <div className="mb-3">
                  <FormikFieldGroup name="value" type="number" placeholder="10" label="Transaction Value" />
                </div>

                <div className="mb-3">
                  <FormikFieldGroup name="netCost" type="number" placeholder="10" label="Net Cost" />
                </div>

                <div className="mb-3">
                  <FormikFieldGroup name="totalCost" type="number" placeholder="Total Cost" label="Total Cost" helpText="Total cost is optional." />
                </div>
              </div>
            </div>

            <div className="mb-3">
              <FormikSelectField name="coo" options={["US", "MX", "CA"].map((countryCode) => ({ name: countryCode, value: countryCode }))} label="Country of Origin*" />
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center gap-2">
            <Link to="/dashboard/create-bill-of-material" replace className="btn btn-outline-primary fw-bold">
              CANCEL & GO BACK
            </Link>
            <button type="submit" className="btn btn-primary fw-bold" disabled={isSubmitting}>
              SAVE & CONTINUE
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default FinishedGoodForm;
